import {Box, Typography} from "@mui/material";
import {AppBar} from "pages/general/appBar";
import {Outlet} from "react-router-dom";

export const Root = ()=>{
	return <Box sx={{display: "flex", flexDirection: "column", minHeight: "100vh", bgcolor: 'background.default'}}>
		{(("standalone" in window.navigator) && !window.navigator.standalone) || !window.matchMedia("(display-mode: standalone)").matches ? <Typography variant={"overline"} sx={{textAlign:"center", width:"100%"}} component={"div"}>Please save this to your Home Screen.</Typography> : false}
		<AppBar />
		<Outlet />
	</Box>
}