import {
	Box,
	Button,
	Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider,
	Grid,
	Stack,
	Table,
	TableBody, TableCell,
	TableContainer, TableHead,
	TableRow, TextField,
	Typography
} from "@mui/material";
import {ArrowCircleRight, ArrowRight} from "@mui/icons-material";
import {SeatPosition} from "helpers/enum/seatPosition";
import {useLoaderData, useNavigate} from "react-router-dom";
import API from "api/api";
import {TripRoutes} from "api/routes/TripRoutes";
import PRODate from "helpers/proDate";
import {useState} from "react";
import {LegStatus} from "helpers/enum/legStatus";
import Api from "api/api";
import {AirportInformation} from "pages/leg/airportInformation";
import reduce from "lodash/reduce";
import flatten from "lodash/flatten";
import {Loader} from "helpers/loader";

export const LegViewData = async ({params})=>{
	return API.get(TripRoutes.leg.get(params.id)).then(result => {
		result.trip.legs[0].departure.time = new PRODate(result.trip.legs[0].departure.time);
		result.trip.legs[0].arrival.time = new PRODate(result.trip.legs[0].arrival.time);
		return Promise.allSettled([
			result.trip.legs[0].departure.time
				.withZoneId(result.trip.legs[0]._calc?.from?.timezone ?? result.trip.legs[0].departure.airport)
				.then(d => {
					result.trip.legs[0].departure.time = d;
				}),
			result.trip.legs[0].arrival.time
				.withZoneId(result.trip.legs[0]._calc?.to?.timezone ?? result.trip.legs[0].arrival.airport)
				.then(d => {
					result.trip.legs[0].arrival.time = d;
				})
		]).then(()=> result);
	})
}

const LegView = ()=>{
	const navigate = useNavigate();
	const data = useLoaderData();
	const [modal, setModal] = useState(null);
	const [showLoader, setShowLoader] = useState(false);
	const [flightId, setFlightId] = useState("");
	const trip = data?.trip ?? {};
	const leg = data?.trip?.legs?.[0] ?? {};

	return <Container sx={{p:0}}>
		<Box position={"static"} sx={{paddingTop:1, paddingBottom:1}}>
			<Container>
				<Button variant={"outlined"} onClick={()=> navigate("/")}>Back</Button>
			</Container>
		</Box>
		<Container sx={{p:1, bgcolor:"secondary.main", color:"secondary.contrastText"}} onClick={()=> {
			setFlightId(leg?.dispatch?.flightId ?? "");
			setModal("flightId")
		}}>
			{trip.flightId ? <Typography variant={"h5"}>Flight {trip.flightId}</Typography> : null}
			<Typography variant={"h5"}>Trip #{trip.tripId}</Typography>
			<Stack direction={"row"} alignItems={"baseline"} spacing={1}>
				<Typography variant={"body1"} fontWeight={"bold"}>{trip.aircraft?.tailNumber}</Typography>
				<Typography variant={"body2"} fontWeight={"light"}>{trip.aircraft?.type?.name}</Typography>
			</Stack>
		</Container>
		<Typography variant={"overline"} textAlign={"center"} component={"div"}>Status: {LegStatus.parse(leg.status).name}</Typography>
		<Divider sx={{width:"50%", margin:"0 auto", marginBottom:1}} />
		<Grid display={"grid"} gridTemplateColumns={"1fr auto 1fr"} gap={"0 8px"} alignItems={"center"}>
			<Typography flexGrow={1} fontWeight={"light"} align={"right"}>{leg._calc?.from?.name}</Typography>
			<div/>
			<Typography flexGrow={1} fontWeight={"light"}>{leg._calc?.to?.name}</Typography>
			<Typography flexGrow={1} variant={"h5"} align={"right"} style={{textDecoration:"underline", cursor:"pointer"}} onClick={()=>{
				setModal("departureAirport");
			}}>{leg.departure?.airport}</Typography>
			<ArrowCircleRight/>
			<Typography flexGrow={1} variant={"h5"} style={{textDecoration:"underline", cursor:"pointer"}} onClick={()=>{
				setModal("arrivalAirport");
			}}>{leg.arrival?.airport}</Typography>
			<Typography flexGrow={1} align={"right"} variant={"caption"}>{leg.departure?.time?.format?.("MM/dd/yyyy")}</Typography>
			<div/>
			<Typography flexGrow={1} variant={"caption"}>{leg.arrival?.time?.format?.("MM/dd/yyyy")}</Typography>
			<Typography flexGrow={1} align={"right"} fontWeight={"light"}>{leg.departure?.time?.format?.("HH:mm Z")}</Typography>
			<div/>
			<Typography flexGrow={1} fontWeight={"light"}>{leg.arrival?.time?.format?.("HH:mm Z")}</Typography>
		</Grid>
		<Typography variant={"body2"} textAlign={"center"}>{leg.passengers?.length ?? leg.passengerCount ?? 0} Passengers</Typography>
		<Divider sx={{mt:2}}/>
		<Button fullWidth={true} onClick={()=> setModal("departure")}>
			<Stack direction={"row"} spacing={1} width={"100%"}>
				<Typography color={"text.primary"}>Departure:</Typography>
				<Typography flexGrow={1} textAlign={"left"}>{leg?.departure?.fbo?.name ?? "No FBO Assigned"}</Typography>
				<ArrowRight />
			</Stack>
		</Button>
		<Divider />
		<Button fullWidth={true} onClick={()=> setModal("arrival")}>
			<Stack direction={"row"} spacing={1} width={"100%"}>
				<Typography color={"text.primary"}>Arrival:</Typography>
				<Typography flexGrow={1} textAlign={"left"}>{leg?.arrival?.fbo?.name ?? "No FBO Assigned"}</Typography>
				<ArrowRight />
			</Stack>
		</Button>
		<Divider style={{marginBottom:20}} />
		<Divider />
		<Button fullWidth={true} onClick={()=> {
			setShowLoader(true);
			Api.get(TripRoutes.leg.tripSheet(leg._id.$oid))
				.then(result =>
					Api._blob("GET", result.url)
				)
				.then(file => {
					navigate("/view?blob="+encodeURIComponent(URL.createObjectURL(file))+"&name="+file.name);
				})
				.catch(()=>{
					setShowLoader(false);
				})
		}}>
			<Stack direction={"row"} spacing={1} width={"100%"}>
				<Typography color={"text.primary"}>View:</Typography>
				<Typography flexGrow={1} textAlign={"left"}>Leg Trip Sheet</Typography>
				<ArrowRight />
			</Stack>
		</Button>
		<Divider />
		<Button fullWidth={true} onClick={()=> {
			setShowLoader(true);
			Api._blob("GET", TripRoutes.leg.mxReview(trip.aircraft._id.$oid).absoluteURL())
				.then(file => {
					navigate("/view?blob="+encodeURIComponent(URL.createObjectURL(file))+"&name="+file.name);
				})
				.catch(()=>{
					setShowLoader(false);
				})
		}}>
			<Stack direction={"row"} spacing={1} width={"100%"}>
				<Typography color={"text.primary"}>View:</Typography>
				<Typography flexGrow={1} textAlign={"left"}>{trip.aircraft?.tailNumber} Maintenance Review</Typography>
				<ArrowRight />
			</Stack>
		</Button>
		<Divider />
		<Button fullWidth={true} onClick={()=> {
			setShowLoader(true);
			Api._blob("GET", TripRoutes.leg.ara(leg._id.$oid).absoluteURL())
				.then(file => {
					navigate("/view?blob="+encodeURIComponent(URL.createObjectURL(file))+"&name="+file.name);
				})
				.catch(()=>{
					setShowLoader(false);
				})
		}}>
			<Stack direction={"row"} spacing={1} width={"100%"}>
				<Typography color={"text.primary"}>View:</Typography>
				<Typography flexGrow={1} textAlign={"left"}>ARA Report</Typography>
				<ArrowRight />
			</Stack>
		</Button>
		<Divider style={{marginBottom:20}} />
		<Divider />
		<Button fullWidth={true} onClick={()=>{
			navigate("riskAssessment");
		}}>
			<Stack direction={"row"} spacing={1} width={"100%"}>
				<Typography color={"text.primary"}>FRAT:</Typography>
				<Typography flexGrow={1} textAlign={"left"}>
					{(leg.riskAssessment) ?
						<span>Score: {reduce(flatten(leg.riskAssessment.answers), function (a, b) {
							return a + b
						}, 0)}</span>
					: "Not Completed"}
				</Typography>
				<ArrowRight />
			</Stack>
		</Button>
		{leg.riskAssessment ? <>
			<Divider />
			<Button fullWidth={true} onClick={()=> {
				setShowLoader(true);
				Api._blob("GET", TripRoutes.leg.riskAudit.report(leg._id.$oid).absoluteURL())
					.then(file => {
						navigate("/view?blob="+encodeURIComponent(URL.createObjectURL(file)));
					})
					.catch(()=>{
						setShowLoader(false);
					})
			}}>
				<Stack direction={"row"} spacing={1} width={"100%"}>
					<Typography color={"text.primary"}>View:</Typography>
					<Typography flexGrow={1} textAlign={"left"}>FRAT Report</Typography>
					<ArrowRight />
				</Stack>
			</Button>
		</>
		: false}
		<Divider style={{marginBottom:20}} />
		<Divider />
		<TableContainer>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell colSpan={2}>
							On-Board
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{leg.pilots?.toSorted((a,b) => a.seat > b.seat).map?.(p => <TableRow key={p.user._id.$oid}>
						<TableCell>{SeatPosition.parse(p.seat).name}</TableCell>
						<TableCell>{p.user?.firstName} {p.user?.lastName}</TableCell>
					</TableRow>)}
					{leg.attendants?.toSorted((a,b) => a.seat > b.seat).map?.(p => <TableRow key={p.user._id.$oid}>
						<TableCell>{SeatPosition.parse(p.seat).name}</TableCell>
						<TableCell>{p.user?.firstName} {p.user?.lastName}</TableCell>
					</TableRow>)}
					{leg.passengers?.toSorted((a,b) => a.seat > b.seat).map?.(p => <TableRow key={p.user._id.$oid}>
						<TableCell>{SeatPosition.parse(p.seat).name}</TableCell>
						<TableCell>
							<Stack direction={"row"} alignItems={"baseline"}>
								<Typography variant={"body2"} flexGrow={2}>{[p.user?.title, p.user?.firstName, p.user?.middleName, p.user?.lastName].filter(Boolean).join(" ")}</Typography>
								<Typography variant={"caption"}>{p.user?.birthday ? new PRODate(p.user?.birthday).format("MM/dd/yyyy") : ""}</Typography>
							</Stack>
							{p.user?.notes ? <Typography variant={"caption"} component={"div"}>{p.user?.notes}</Typography> : false}
						</TableCell>
					</TableRow>)}
				</TableBody>
			</Table>
		</TableContainer>
		<FBOInfo fbo={leg?.departure?.fbo ?? {}} isOpen={modal==="departure"} onClose={()=> setModal(null)} />
		<FBOInfo fbo={leg?.arrival?.fbo ?? {}} isOpen={modal==="arrival"} onClose={()=> setModal(null)} />
		<Dialog open={modal==="departureAirport"} onClose={()=> setModal(null)}>
			<DialogContent>
				<AirportInformation icao={leg?.departure?.airport} />
			</DialogContent>
		</Dialog>
		<Dialog open={modal==="arrivalAirport"} onClose={()=> setModal(null)}>
			<DialogContent>
				<AirportInformation icao={leg?.arrival?.airport} />
			</DialogContent>
		</Dialog>
		<Dialog open={modal==="flightId"} onClose={()=> setModal(null)}>
			<DialogContent>
				<TextField label={"Flight ID"} value={flightId} onChange={(ev)=> setFlightId(ev.target.value)} />
			</DialogContent>
			<DialogActions>
				<Button onClick={()=>{
					API.post(TripRoutes.trip.save(), {
						"_id": leg.dispatch._id,
						"path": "flightId",
						"value": flightId
					}).then(()=> window.location.reload())
				}}>Save</Button>
			</DialogActions>
		</Dialog>
		<Loader show={showLoader} />
	</Container>
}
export default LegView;

const FBOInfo = ({fbo, isOpen, onClose})=>{
	const other = Object.assign({}, fbo);
	delete other.address;
	delete other.id;
	delete other.loc;
	delete other.comms;
	delete other.note;
	delete other.name;

	return <Dialog open={isOpen} onClose={onClose}>
		<DialogTitle>{fbo.name}</DialogTitle>
		<DialogContent>
			<DialogContentText>{fbo.note}</DialogContentText>
			<Divider sx={{mt:1}}/>
			<TableContainer>
				<Table sx={{"> td": {padding:0}}}>
					<TableBody>
						{fbo.address ?
							<TableRow>
								<TableCell>Address</TableCell>
								<TableCell>
									<address>
										<div>{fbo.address.street}</div>
										<div>{fbo.address.city}, {fbo.address.state} {fbo.address.zip}</div>
									</address>
								</TableCell>
							</TableRow>
						: false}
						{Object.entries(fbo.comms ?? {})?.map?.(([k,v]) => <TableRow key={k}>
							<TableCell>{k}</TableCell>
							<TableCell>{v}</TableCell>
						</TableRow>)}
						{Object.entries(other ?? {})?.map?.(([k,v]) => <TableRow key={k}>
							<TableCell>{k}</TableCell>
							<TableCell><pre>{v?.join?.("\r\n") ?? v}</pre></TableCell>
						</TableRow>)}
					</TableBody>
				</Table>
			</TableContainer>
		</DialogContent>
		<DialogActions>
			<Button onClick={onClose}>Close</Button>
		</DialogActions>
	</Dialog>
}