import Route from "./route";

export const TripRoutes = {
	list: {
		between: ()=> new Route({method: "POST", url: "/api/user/flights"}),
	},
	trip: {
		create: ()=> new Route({method: "POST", url: "/api/dispatch/create"}),
		save: ()=> new Route({method: "POST", url: "/api/dispatch/leg/trip/item"}),
		release: (id)=> new Route({
			method: "GET",
			url: "/api/dispatch/" + encodeURIComponent((function (k, v) {
				return v
			})("id", id)) + "/release"
		}),
	},
	leg: {
		get: (id)=> new Route({method: "GET", url: "/api/leg/" + encodeURIComponent(id)}),
		save: ()=> new Route({method: "POST", url: "/api/dispatch/leg/prerelease/item"}),
		savePilotItem: ()=> new Route({method:"POST", url:"/api/dispatch/leg/pilot/item"}),
		markLegAsReleased: (id)=> new Route({method: "POST", url: "/api/dispatch/" + encodeURIComponent((function (k, v) {
				return v
			})("id", id)) + "/release"
		}),
		release: (id)=> new Route({
			method: "GET",
			url: "/api/leg/" + encodeURIComponent((function (k, v) {
				return v
			})("id", id)) + "/release"
		}),
		saveAssignment: (id, kind)=> new Route({method: "POST", url:"/api/leg/" + encodeURIComponent((function (k, v) {
				return v
			})("id", id)) + "/user/" + encodeURIComponent((function (k, v) {
				return v
			})("kind", kind))}),
		weather: (icao)=> new Route({
			method: "GET", url: "/utils/weather/" + encodeURIComponent((function (k, v) {
				return v
			})("icao", icao))
		}),
		genDec: (id)=> new Route({
			method: "GET", url: "/reports/gendec/" + encodeURIComponent((function (k, v) {
				return v
			})("id", id))
		}),
		internationalManifest: (id)=> new Route({
			method: "GET", url: "/reports/internationalManifest/" + encodeURIComponent((function (k, v) {
				return v
			})("id", id))
		}),
		ara: (id)=> new Route({
			method: "GET", url: "/reports/cya/" + encodeURIComponent((function (k, v) {
				return v
			})("id", id))
		}),
		tripSheet: (id)=> new Route({
			method: "GET", url: "/api/leg/" + encodeURIComponent((function (k, v) {
				return v
			})("id", id)) + "/review/tripSheet"
		}),
		mxReview: (id)=> new Route({
			method: "GET", url: "/reports/mxReview/" + encodeURIComponent((function (k, v) {
				return v
			})("id", id))
		}),
		loadManifest: {
			get: (id)=> new Route({
				method: "GET", url: "/api/leg/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id)) + "/loadManifest"
			}),
			save: (id) => new Route({
				method: "POST", url: "/api/leg/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id)) + "/loadManifest"
			}),
			file: {
				add: (id)=> new Route({
					method: "POST", url: "/api/leg/" + encodeURIComponent((function (k, v) {
						return v
					})("id", id)) + "/loadManifest/file/add"
				}),
				remove: (id, image)=> new Route({
					method: "DELETE", url: "/api/leg/" + encodeURIComponent((function (k, v) {
						return v
					})("id", id)) + "/loadManifest/file/remove/" + encodeURIComponent((function (k, v) {
						return v
					})("image", image))
				}),
			}
		},
		riskAudit: {
			get: (id)=> new Route({
				method: "GET", url: "/api/leg/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id)) + "/riskAudit"
			}),
			save: (id) => new Route({
				method: "POST", url: "/api/leg/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id)) + "/riskAudit"
			}),
			report: (id)=> new Route({
				method: "GET", url: "/reports/riskAssessment/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id))
			})
		},
		postFlight: {
			images: {
				add: (id)=> new Route({
					method: "POST", url: "/api/leg/" + encodeURIComponent((function (k, v) {
						return v
					})("id", id)) + "/postFlight/image/add"
				}),
				remove: (id, image)=> new Route({
					method: "DELETE", url: "/api/leg/" + encodeURIComponent((function (k, v) {
						return v
					})("id", id)) + "/postFlight/image/remove/" + encodeURIComponent((function (k, v) {
						return v
					})("image", image))
				}),
			}
		}
	},
}