import {fetchAuthSession} from "aws-amplify/auth";
import {createContext, useEffect, useMemo, useState} from "react";
import {Part} from "helpers/enum/part";
import {useDispatch} from "react-redux";
import {setUser} from "data/userReducer";
import {setOperation, setTools} from "data/operationReducer";
import API from "api/api";
import {Api} from "api/routes/Api";
import {Application} from "api/routes/Application";
import {setCurrentDuty} from "data/currentDutyReducer";
import Dashboard from "pages/dashboard";
import {Authenticator, useAuthenticator} from "@aws-amplify/ui-react";
import {
	Box,
	createTheme,
	ThemeProvider,
	useMediaQuery
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import DutyView, {DutyViewData} from "pages/duty/view";
import LegView, {LegViewData} from "pages/leg/view";
import {View, ViewerData} from "pages/view/view";
import {ThemeProvider as AWSThemeProvider} from "@aws-amplify/ui-react";
import {RiskAssessment, RiskAssessmentData} from "pages/leg/riskAssessment";
import {Root} from "pages/general/root";
import {CreateLeg, CreateLegData} from "pages/create/leg";

export const DatePickerFieldContext = createContext();

function App() {
	const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
	const {user} = useAuthenticator((context) => [context.user]);
	const {route} = useAuthenticator(context => [context.route]);
	const dispatch = useDispatch();
	const [field, setField] = useState(null);
	const [currentUser, setCurrentUser] = useState(null);

	const theme = useMemo(
		() =>
			createTheme({
				palette: {
					mode: prefersDarkMode ? 'dark' : 'light',
					primary: {
						main: prefersDarkMode ? "#60ACEE" : "#337ab7"
					},
					secondary: {
						main: prefersDarkMode ? "#91a0b9" : "#778899"
					},
					error: {
						main: prefersDarkMode ? "#F25744" : "#d9483b"
					},
					warning: {
						main: prefersDarkMode ? "#f9dd39" : "#F2BC57"
					},
					info: {
						main: prefersDarkMode ? "#7ec4f5" : "#5bc0de"
					},
					success: {
						main: prefersDarkMode ? "#88cb88" : "#5cb85c"
					}
				},
			}),
		[prefersDarkMode],
	);

	useEffect(() => {
		if (!user) return;
		fetchAuthSession()
			.then(user => {
				const token = user.tokens.idToken;
				const u = {
					_id: {"$oid": token.payload.preferred_username},
					fullName: `${token.payload.given_name} ${token.payload.family_name}`,
					userTypes: (token.payload["cognito:roles"] || []),
					operations: (token.payload["custom:operations"] || "").split(",")
				};
				dispatch(setUser(u));
				setCurrentUser(u);

				let operation = {
					_id: {"$oid": token.payload.website},
					name: token.payload.name,
					part: Part.parse(parseInt(token.payload.part || 1))
				}
				try {
					operation.options = JSON.parse(token.payload.options);
				} catch (e) {
				}
				dispatch(setOperation(operation));

				return API.get(Api.getTools())
					.then(result => {
						if (result.tools) {
							dispatch(setTools(result.tools));
						}
					})
					.then(() =>
						API.get(Application.loginCheck())
					)
					.then(result => {
						dispatch(setCurrentDuty(result.duty));
					})
					.catch(e => {
						console.error("Caught Error", e);
					});
			})
			.catch(() => {
				dispatch(setUser({}));
				dispatch(setOperation({}));
			});
	}, [user, dispatch])

	const router = createBrowserRouter([
		{
			path: "/",
			element: <Root />,
			children: [
				{
					index: true,
					element: <Dashboard />
				},
				{
					path: "/create",
					loader: CreateLegData,
					element: <CreateLeg />
				},
				{
					path: "/duty/:id",
					loader: (params)=> DutyViewData(params, currentUser?._id?.$oid),
					element: <DutyView />
				},
				{
					path: "/leg/:id",
					loader: LegViewData,
					element: <LegView />
				},
				{
					path: "/leg/:id/riskAssessment",
					loader: RiskAssessmentData,
					element: <RiskAssessment />
				},
				{
					path: "/view",
					loader: ViewerData,
					element: <View />
				},
			]
		}
	]);

	return <ThemeProvider theme={theme}>
		<CssBaseline enableColorScheme/>

		{route === "authenticated" ?
			<DatePickerFieldContext.Provider value={{field: field, setField: setField}}>
				<RouterProvider router={router} />
			</DatePickerFieldContext.Provider>
			:
			<Box sx={{display: "grid", alignItems: "center", minHeight: "100vh"}}>
				<AWSThemeProvider colorMode={"system"} theme={{
					name:"lf-theme",
					tokens: {
						colors: {
							font: {
								secondary: prefersDarkMode ? "#FFFFFF" : "#333333"
							},
							background: {
								primary: prefersDarkMode ? "#333333" : "#FFFFFF"
							},
							teal: {
								10: prefersDarkMode ? "#60ACEE" : "#337ab7",
								20: prefersDarkMode ? "#60ACEE" : "#337ab7",
								30: prefersDarkMode ? "#60ACEE" : "#337ab7",
								40: prefersDarkMode ? "#60ACEE" : "#337ab7",
								50: prefersDarkMode ? "#60ACEE" : "#337ab7",
								60: prefersDarkMode ? "#60ACEE" : "#337ab7",
								70: prefersDarkMode ? "#60ACEE" : "#337ab7",
								80: prefersDarkMode ? "#60ACEE" : "#337ab7",
								90: prefersDarkMode ? "#60ACEE" : "#337ab7",
								100: prefersDarkMode ? "#60ACEE" : "#337ab7",
							}
						},
					}
				}}>
					<Authenticator hideSignUp={true} />
				</AWSThemeProvider>
			</Box>
		}
	</ThemeProvider>
}

export default App;
