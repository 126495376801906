import sortBy from "lodash/sortBy";
import difference from "lodash/difference";
import intersection from "lodash/intersection";
import union from "lodash/union";

export const UserType = function(){
	let obj = {
		notJust: function(tests, roles) {
			let test = difference(roles, tests);
			return test.length > 0;
		},
		parse: function(id){
			if (id instanceof Value) {
				return this.list().find(i => i.id===id.id);
			}
			return this.list().find(i => i.id===id || i.arn===id);
		},
		list: function(){ return sortBy([
			this.SuperAdmin,
			this.Auditor,
			this.AircraftiPad,
			this.PrimaryAdmin,
			this.OpsControl,
			this.CheckAirman,
			this.Pilot,
			this.FAA,
			this.AircraftOwner,
			this.Screener,
			this.Mechanic,
			this.GroundInstructor,
			this.TCE,
			this.FlightAttendant,
			this.SafetyOfficer,
			this.Accounting,
			this.TSAUploader,
			this.ScheduleRelease,
			this.Scheduler,
			this.SafetyCommittee,
			this.LineService,
			this.Sales,
			this.Broker,
			this.MXControl,
			this.NoAccess,
			this.SAFA,
			this.RecordsManager,
			this.GeneralCalendar,
		], 'name')}
	};

	class Value {
		constructor(id, name, arn, parents){
			this.id = id;
			this.name = name;
			this.arn = arn;
			this.parents = parents || [];
		}

		check(roles) {
			let test = intersection(roles, union(this.parents, [this, UserType.SuperAdmin]));
			return test.length > 0;
		}

		isExactly(roles) {
			let test = intersection(roles, [this]);
			return test.length > 0;
		}

		isOnly(roles) {
			if ((roles||[]).length > 1) return false;
			return this.isExactly(roles);
		}
	}

	obj.SuperAdmin = new Value(0, "Super Admin", "arn:aws:iam::245211809793:role/LF-SuperAdmin");
	obj.Auditor = new Value(1, "Auditor", "arn:aws:iam::245211809793:role/LF-Auditor");
	obj.AircraftiPad = new Value(2, "Aircraft iPad", "arn:aws:iam::245211809793:role/LF-AircraftiPad");
	obj.PrimaryAdmin = new Value(3, "Primary Admin", "arn:aws:iam::245211809793:role/LF-PrimaryAdmin");
	obj.CheckAirman = new Value(4, "Check Airman", "arn:aws:iam::245211809793:role/LF-CheckAirman");
	obj.OpsControl = new Value(25, "Ops Control", "arn:aws:iam::245211809793:role/LF-OpsControl", [obj.PrimaryAdmin]);
	obj.Pilot = new Value(6, "Pilot", "arn:aws:iam::245211809793:role/LF-Pilot", [obj.PrimaryAdmin, obj.OpsControl]);
	obj.FAA = new Value(7, "FAA", "arn:aws:iam::245211809793:role/LF-FAA");
	obj.AircraftOwner = new Value(8, "Aircraft Owner", "arn:aws:iam::245211809793:role/LF-AircraftOwner", [obj.PrimaryAdmin, obj.OpsControl]);
	obj.Screener = new Value(9, "Screener", "arn:aws:iam::245211809793:role/LF-Screener", [obj.PrimaryAdmin, obj.OpsControl]);
	obj.Mechanic = new Value(10, "Maintenance", "arn:aws:iam::245211809793:role/LF-Mechanic", [obj.PrimaryAdmin, obj.OpsControl]);
	obj.GroundInstructor = new Value(11, "Ground Instructor", "arn:aws:iam::245211809793:role/LF-GroundInstructor");
	obj.TCE = new Value(12, "Training Center Evaluator", "arn:aws:iam::245211809793:role/LF-TCE");
	obj.FlightAttendant = new Value(13, "Flight Attendant", "arn:aws:iam::245211809793:role/LF-FlightAttendant", [obj.PrimaryAdmin, obj.OpsControl]);
	obj.SafetyOfficer = new Value(14, "Safety Officer", "arn:aws:iam::245211809793:role/LF-SafetyOfficer", [obj.PrimaryAdmin, obj.OpsControl]);
	obj.Accounting = new Value(15, "Accounting", "arn:aws:iam::245211809793:role/LF-Accounting", [obj.PrimaryAdmin, obj.OpsControl]);
	obj.TSAUploader = new Value(16, "TSA 12-5", "arn:aws:iam::245211809793:role/LF-TSAUploader");
	obj.ScheduleRelease = new Value(17, "Dispatcher", "arn:aws:iam::245211809793:role/LF-ScheduleRelease", [obj.PrimaryAdmin, obj.OpsControl]);
	obj.Scheduler = new Value(5, "Scheduler", "arn:aws:iam::245211809793:role/LF-Scheduler", [obj.PrimaryAdmin, obj.OpsControl, obj.ScheduleRelease]);
	obj.SafetyCommittee = new Value(18, "Safety Committee", "arn:aws:iam::245211809793:role/LF-SafetyCommittee", [obj.PrimaryAdmin, obj.OpsControl, obj.SafetyOfficer]);
	obj.LineService = new Value(19, "Line Service", "arn:aws:iam::245211809793:role/LF-LineService", [obj.PrimaryAdmin, obj.OpsControl]);
	obj.Sales = new Value(20, "Charter Sales", "arn:aws:iam::245211809793:role/LF-Sales", [obj.PrimaryAdmin, obj.OpsControl, obj.Scheduler, obj.ScheduleRelease]);
	obj.Broker = new Value(21, "Broker", "arn:aws:iam::245211809793:role/LF-Broker", [obj.PrimaryAdmin, obj.OpsControl, obj.Scheduler, obj.ScheduleRelease]);
	obj.MXControl = new Value(22, "MX Control", "arn:aws:iam::245211809793:role/LF-MXControl", [obj.PrimaryAdmin, obj.OpsControl]);
	obj.NoAccess = new Value(23, "No Access", "arn:aws:iam::245211809793:role/LF-NoAccess");
	obj.SAFA = new Value(24, "SAFA", "arn:aws:iam::245211809793:role/LF-SAFA");
	obj.RecordsManager = new Value(26, "Records Manager", "arn:aws:iam::245211809793:role/LF-RecordsManager");
	obj.GeneralCalendar = new Value(27, "General Calendar", "arn:aws:iam::245211809793:role/LF-GeneralCalendar");

	return obj;
}();