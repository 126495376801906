import {useDispatch, useSelector} from "react-redux";
import {selectUser} from "data/userReducer";
import {selectCurrentDuty, setCurrentDuty} from "data/currentDutyReducer";
import React, {useState} from "react";
import API from "api/api";
import {Api} from "api/routes/Api";
import {
	Button,
	Card,
	CardContent,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle, Stack,
	Table, TableBody, TableCell,
	TableContainer, TableRow, Typography
} from "@mui/material";

const DutyBlock = ()=>{
	const dispatch = useDispatch();
	const user = useSelector(selectUser);
	const currentDuty = useSelector(selectCurrentDuty);
	const [showDuty, setShowDuty] = useState(false);

	const dutyIn = ()=>{
		API.post(Api.fitForDuty(), {})
			.then(result => {
				dispatch(setCurrentDuty(result.fitForDuty))
			});
	}

	const dutyOut = ()=>{
		API.post(Api.dutyOut(), {})
			.then(() => {
				dispatch(setCurrentDuty({}))
			});
	}

	return <Card>
		<CardContent>
			{currentDuty.message ?
				<Dialog onClose={()=> setShowDuty(false)} open={showDuty}>
					<DialogTitle>Current Duty</DialogTitle>
					<DialogContent>
						<DialogContentText dangerouslySetInnerHTML={{__html: currentDuty.message}}/>
						<TableContainer>
							<Table>
								<TableBody>
									<TableRow>
										<TableCell sx={{borderTop:1}}>Type</TableCell>
										<TableCell sx={{borderTop:1}}>{currentDuty.type}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Start</TableCell>
										<TableCell>{currentDuty.start ? currentDuty.start.format() : "-"}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>End</TableCell>
										<TableCell>{currentDuty.end ? currentDuty.end.format() : "-"}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>MOT</TableCell>
										<TableCell>{currentDuty.mot ? currentDuty.mot.format() : "-"}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>CMOT</TableCell>
										<TableCell>{currentDuty.cmot ? currentDuty.cmot.format() : "-"}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Max Flight</TableCell>
										<TableCell>{`${currentDuty.flight} hrs`}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Max Duty</TableCell>
										<TableCell>{`${currentDuty.duty} hrs`}</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
						{/* TODO: Add something for the legs[] */}
					</DialogContent>
				</Dialog>
				: false}
			<Typography variant={"h5"}>{user.fullName}</Typography>
			<Stack direction={"row"}>
				<div style={{flexGrow: 2}}/>
				{currentDuty.message ?
					<>
						<Button variant={"outline"} onClick={()=> setShowDuty(true)}>View Duty</Button>
						<Button variant={"contained"} onClick={dutyOut}>Duty Out</Button>
					</>
					:
					<Button variant={"contained"} onClick={dutyIn}>Duty In</Button>
				}
			</Stack>
		</CardContent>
	</Card>;
}
export default DutyBlock;