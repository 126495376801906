export class InvalidDateError extends TypeError {
	constructor(message) {
		super(message);
		this.name = "InvalidDateError";
	}
}

function asDate(self) {
	return new Date(self.date.getTime());
}

export default class PROBaseDate {
	static now() {
		return new PROBaseDate()
	}

	static dayLabels() {
		return ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
	}

	static monthLabels() {
		return ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
	}

	static monthShortLabels() {
		return ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
	}

	static daysInMonth(month, year) {
		if (month === 1) {
			if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
				return 29;
			}
		}
		return [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
	}

	static isDate(timestamp) {
		if (timestamp instanceof PROBaseDate) {
			return true;
		}
		if (timestamp instanceof Date) {
			return false;
		}
		if (timestamp instanceof Object) {
			if (timestamp?.["$long"] && timestamp.$long.toString().length >= 10 && timestamp.$long.toString().length <= 15) {
				return true;
			}
			if (timestamp?.["$date"]) {
				return true;
			}
		}
		return Number.isFinite(timestamp) && timestamp.toString().length >= 10 && timestamp.toString().length <= 15;
	}

	constructor(timestamp, offset, label) {
		if (timestamp instanceof PROBaseDate) {
			timestamp = timestamp.timestamp;
			offset = timestamp.offset;
			label = timestamp.label;
		}
		if (timestamp instanceof Date) {
			timestamp = timestamp.getTime();
		}
		if (timestamp instanceof Object) {
			if (timestamp?.["$long"]) {
				timestamp = parseInt(timestamp.$long);
			}
			if (timestamp?.["$date"]) {
				timestamp = timestamp.$date;
			}
			if (timestamp?.["$numberLong"]) {
				timestamp = parseInt(timestamp.$numberLong);
			}
		}
		if (!Number.isFinite(timestamp)) {
			timestamp = Date.now();
		}
		if (!Number.isFinite(offset)) {
			offset = 0;
		}
		if (Number.isNaN(timestamp)) {
			throw new InvalidDateError("Invalid timestamp. " + timestamp);
		}
		this.offset = offset;
		this.timestamp = timestamp;
		this.date = new Date(this.timestamp + (this.offset * 60 * 60 * 1000));
		this.timezoneLabel = (typeof label === 'string') ? label : undefined;
		this._realDate = this.format();
		Object.freeze(this);
	}

	toString() {
		if (!this.date) return "No date set.";
		let minutes = this.minutes < 10 ? `0${this.minutes}` : this.minutes.toString();
		return `${this.month + 1}/${this.day}/${this.year} @ ${this.hours}:${minutes} ${this.timezone}`;
	}

	get dayOfWeek() {
		if (!this.date) return undefined;
		return this.date.getUTCDay();
	}

	get hours() {
		if (!this.date) return undefined;
		return this.date.getUTCHours();
	}

	get minutes() {
		if (!this.date) return undefined;
		return this.date.getUTCMinutes();
	}

	get seconds() {
		if (!this.date) return undefined;
		return this.date.getUTCSeconds();
	}

	get day() {
		if (!this.date) return undefined;
		return this.date.getUTCDate();
	}

	get month() {
		if (!this.date) return undefined;
		return this.date.getUTCMonth();
	}

	get year() {
		if (!this.date) return undefined;
		return this.date.getUTCFullYear();
	}

	get monthLabel() {
		if (!this.date) return undefined;
		return PROBaseDate.monthLabels()[this.month];
	}

	get dayLabel() {
		if (!this.date) return undefined;
		return PROBaseDate.dayLabels()[this.dayOfWeek]
	}

	get timezone() {
		if (this.timezoneLabel) return this.timezoneLabel;
		let out = this.offset || 0;
		if (out > 0) {
			out = "+" + out;
		} else if (out === 0) {
			out = "Z"
		}
		return out;
	}

	get simpleTimezone() {
		if (Number.isFinite(this.offset) && this.offset === 0) {
			if (typeof this.timezoneLabel === 'string') {
				return "L"
			}
			return "Z";
		}
		return "L";
	}

	get firstOfMonth() {
		return this.startOfDay().withDay(1);
	}

	get lastOfMonth() {
		return this.withDay(this.daysInMonth).endOfDay();
	}

	get daysInMonth() {
		return PROBaseDate.daysInMonth(this.month, this.year);
	}

	get dayOfYear() {
		const a = Date.UTC(this.year, this.month, this.day);
		const b = Date.UTC(this.year, 0, 0);
		return (a - b) / 24 / 60 / 60 / 1000;
	}

	get dayOfYearRelative() {
		const a = Date.UTC(this.date.getFullYear(), this.date.getMonth(), this.date.getDate());
		const b = Date.UTC(this.date.getFullYear(), 0, 0);
		return (a - b) / 24 / 60 / 60 / 1000;
	}

	toJSON() {
		return this.timestamp;
	}

	clone() {
		return new PROBaseDate(this.timestamp, this.offset, this.timezoneLabel);
	}

	valueOf() {
		return this.timestamp;
	}

	getTime() {
		return this.timestamp;
	}

	withOffset(offset, label) {
		return new PROBaseDate(this.timestamp, offset, label);
	}

	withTimestamp(timestamp) {
		return new PROBaseDate(timestamp, this.offset, this.timezoneLabel);
	}

	withYear(value) {
		let date = asDate(this).setUTCFullYear(value);
		return this.withTimestamp(date - (this.offset * 60 * 60 * 1000));
	}

	withMonth(value) {
		let date = asDate(this).setUTCMonth(value);
		return this.withTimestamp(date - (this.offset * 60 * 60 * 1000));
	}

	withDay(value) {
		let date = asDate(this).setUTCDate(value);
		return this.withTimestamp(date - (this.offset * 60 * 60 * 1000));
	}

	withHours(value) {
		let date = asDate(this).setUTCHours(value);
		return this.withTimestamp(date - (this.offset * 60 * 60 * 1000));
	}

	withMinutes(value) {
		let date = asDate(this).setUTCMinutes(value);
		return this.withTimestamp(date - (this.offset * 60 * 60 * 1000));
	}

	withSeconds(value, nanos) {
		if (!Number.isFinite(nanos)) {
			nanos = 0;
		}
		let date = asDate(this).setUTCSeconds(value, nanos);
		return this.withTimestamp(date);
	}

	withDayOfWeek(offset) {
		return this.withDay(this.day - (this.dayOfWeek - offset));
	}

	pushingOffset(offset) {
		return new PROBaseDate(this.timestamp - (offset * 60 * 60 * 1000), offset, this.timezoneLabel);
	}

	async withZoneId(zone) {
		return this.calculateTimezone(zone).then(([offset, label]) => new PROBaseDate(this.timestamp, offset, label))
	}

	async pushingZoneId(zone) {
		return this.calculateTimezone(zone).then(([offset, label]) => {
			return new PROBaseDate(this.timestamp - (offset * 60 * 60 * 1000), offset, label)
		});
	}

	nextDay() {
		return this.plus(24 * 60);
	}

	prevDay() {
		return this.minus(24 * 60);
	}

	nextMonth() {
		const [dM, dY] = (() => {
			let dM = this.month + 1;
			let dY = this.year;
			if (dM >= 12) {
				dM = 0;
				dY++;
			}
			return [dM, dY]
		})()
		return this.withDay(1).withMonth(dM).withYear(dY);
	}

	lastMonth() {
		const [dM, dY] = (() => {
			let dM = this.month - 1;
			let dY = this.year;
			if (dM < 0) {
				dM = 11;
				dY--;
			}
			return [dM, dY]
		})()
		return this.withDay(1).withMonth(dM).withYear(dY);
	}

	nextYear() {
		let dY = this.year + 1;
		let date = asDate(this).setUTCFullYear(dY);
		return this.withTimestamp(date - (this.offset * 60 * 60 * 1000));
	}

	lastYear() {
		let dY = this.year - 1;
		let date = asDate(this).setUTCFullYear(dY);
		return this.withTimestamp(date - (this.offset * 60 * 60 * 1000));
	}

	startOfDay() {
		let date = asDate(this).setUTCHours(0, 0, 0, 0);
		return this.withTimestamp(date - (this.offset * 60 * 60 * 1000));
	}

	endOfDay() {
		let date = asDate(this).setUTCHours(23, 59, 59, 999);
		return this.withTimestamp(date - (this.offset * 60 * 60 * 1000));
	}

	minus(minutes) {
		return this.withTimestamp(this.timestamp - (minutes * 60 * 1000));
	}

	plus(minutes) {
		return this.withTimestamp(this.timestamp + (minutes * 60 * 1000));
	}

	clamp(low, high) {
		if (!(low instanceof PROBaseDate)) {
			return this
		}
		if (!(high instanceof PROBaseDate)) {
			return this
		}
		if (this.isSameOrBefore(low)) return this.withTimestamp(low.pushingOffset(this.offset-low.offset).timestamp);
		if (this.isSameOrAfter(high)) return this.withTimestamp(high.pushingOffset(this.offset-high.offset).timestamp);
		return this;
	}

	format(fmt) {
		if (Number.isNaN(this.timestamp)) {
			return ""
		}
		if (!(this.date instanceof Date)) {
			return ""
		}
		let out = ((typeof fmt === 'string') && fmt.length > 0) ? fmt : "MM/dd/yyyy HH:mm Z";
		out = out.replace("HH", this.hours.toString().padStart(2, "0"));
		out = out.replace("mm", this.minutes.toString().padStart(2, "0"));
		out = out.replace("YYYY", this.year.toString().padStart(4, "0"));
		out = out.replace("yyyy", this.year.toString().padStart(4, "0"));
		out = out.replace("dd", this.day.toString().padStart(2, "0"));
		out = out.replace("d", this.day.toString());
		out = out.replace("DDD", PROBaseDate.dayLabels()[this.dayOfWeek]);
		out = out.replace("DD", this.day.toString().padStart(2, "0"));
		out = out.replace("MMM", PROBaseDate.monthShortLabels()[this.month]);
		out = out.replace("MM", (this.month + 1).toString().padStart(2, "0"));
		out = out.replace("YY", this.year.toString().substring(2));
		out = out.replace("yy", this.year.toString().substring(2));
		if (out.includes("ZZ")) {
			out = out.replace("ZZ", this.simpleTimezone);
		} else {
			out = out.replace("Z", this.timezone);
		}
		out = out.replace("WWW", PROBaseDate.dayLabels()[this.dayOfWeek]);
		return out;
	}

	isSameOrAfter(comp) {
		if (!(comp instanceof PROBaseDate)) {
			return false
		}
		return this.timestamp >= (comp.timestamp || 0);
	}

	isSameOrBefore(comp) {
		if (!(comp instanceof PROBaseDate)) {
			return false
		}
		return this.timestamp <= (comp.timestamp || 0);
	}

	isSameDay(comp) {
		if (!(comp instanceof PROBaseDate)) {
			return false
		}
		return (this.startOfDay().timestamp <= comp.timestamp && this.endOfDay().timestamp >= comp.timestamp) || (comp.startOfDay().timestamp <= this.timestamp && comp.endOfDay().timestamp >= this.timestamp);
	}

	isBetween(date1, date2) {
		return this.isSameOrAfter(date1) && this.isSameOrBefore(date2);
	}

	isSameOrAfterRelative(comp) {
		if (!(comp instanceof PROBaseDate)) {
			return false
		}
		return this.date >= comp.date;
	}

	isSameOrBeforeRelative(comp) {
		if (!(comp instanceof PROBaseDate)) {
			return false
		}
		return this.date <= comp.date;
	}

	isSameRelativeDay(comp) {
		if (!(comp instanceof PROBaseDate)) {
			return false
		}
		let cT = comp.date.getTime();
		let tT = this.date.getTime();
		return (this.startOfDay().date.getTime() <= cT && this.endOfDay().date.getTime() >= cT) ||
			(comp.startOfDay().date.getTime() <= tT && comp.endOfDay().date.getTime() >= tT);
	}

	isBetweenRelative(date1, date2) {
		return this.isSameOrAfterRelative(date1) && this.isSameOrBeforeRelative(date2);
	}

	isBeforeRelative(comp) {
		if (!(comp instanceof PROBaseDate)) {
			return false
		}
		return this.date < comp.date;
	}

	async calculateTimezone() {
		console.log("calculateTimezone cannot be used on PROBaseDate");
		return Promise.resolve([]);
	}

	minutesTill(next) {
		if (!(next instanceof PROBaseDate)) {
			return 0
		}
		return (next.timestamp - this.timestamp) / 1000 / 60;
	}

	static equals(augend, addend) {
		return augend?.timestamp === addend?.timestamp && augend?.offset === addend?.offset;
	}

	inspect() {
		return {timestamp: this.timestamp, offset: this.offset, date: this.format(), label: this.timezoneLabel};
	}

	fromNow(date) {
		let _diff = (((date ?? new Date()).getTime()) - this.timestamp) / 1000;

		function tense(time) {
			let diff = Math.abs(time);
			if (diff < 44) {
				return "a few seconds";
			}
			if (diff < 119) {
				return "a minute";
			}
			if (diff < 59 * 60) {
				let test = parseInt(diff / 60);
				return test + " minute" + (test > 1 ? "s" : "");
			}
			if (diff < 119 * 60) {
				return "an hour";
			}
			if (diff < 23 * 60 * 60) {
				let test = parseInt(diff / 60 / 60);
				return test + " hour" + (test > 1 ? "s" : "");
			}
			if (diff < 47 * 60 * 60) {
				return "a day";
			}
			if (diff < 28 * 60 * 60 * 24) {
				let test = Math.round(diff / 60 / 60 / 24);
				return test + " day"+ (test > 1 ? "s" : "");
			}
			if (diff < 56 * 60 * 60 * 24) {
				return "a month";
			}
			if (diff < 360 * 60 * 60 * 24) {
				let test = Math.round(diff / 60 / 60 / 24 / 30)
				return test + " month" + (test > 1 ? "s" : "");
			}
			if (diff < 720 * 60 * 60 * 24) {
				return "a year";
			}
			let test = Math.round(diff / 60 / 60 / 24 / 365);
			return test + " year" + (test > 1 ? "s" : "");
		}

		return _diff >= 0 ? tense(_diff) + " ago" : "in " + tense(_diff);
	}
}
