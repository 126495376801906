import Api from "../api/api";
import {Api as ApiRoutes} from "../api/routes/Api";
import PROBaseDate from "./proBaseDate";

export default class PRODate extends PROBaseDate {
	static now() {
		return new PRODate()
	}

	static compare(augend, addend) {
		if (augend.timestamp === addend.timestamp) return 0;
		if (augend.timestamp > addend.timestamp) return 1;
		if (augend.timestamp < addend.timestamp) return -1;
	}

	static compareLocal(augend, addend) {
		return augend.date - addend.date;
	}

	constructor(timestamp, offset, label) {
		if (timestamp instanceof PRODate) {
			timestamp = timestamp.timestamp;
			offset = timestamp.offset;
			label = timestamp.label;
		}
		super(timestamp, offset, label);
	}

	clone() {
		return new PRODate(this.timestamp, this.offset, this.timezoneLabel);
	}

	withOffset(offset, label) {
		return new PRODate(this.timestamp, offset, label);
	}

	withTimestamp(timestamp) {
		return new PRODate(timestamp, this.offset, this.timezoneLabel);
	}

	pushingOffset(offset) {
		return new PRODate(this.timestamp - (offset * 60 * 60 * 1000), offset, this.timezoneLabel);
	}

	async withZoneId(zone) {
		return this.calculateTimezone(zone).then(([offset, label]) => new PRODate(this.timestamp, offset, label))
	}

	async pushingZoneId(zone) {
		return this.calculateTimezone(zone).then(([offset, label]) => {
			return new PRODate(this.timestamp - (offset * 60 * 60 * 1000), offset, label)
		});
	}

	async calculateTimezone(zoneId) {
		if (typeof zoneId === "string") {
			if (window.db) {
				return window.db.get("timezones", [this.timestamp, zoneId])
					.then(r=> {
						if (!r) throw new Error("No timezone found locally.");
						return r;
					})
					.catch(()=> {
						try {
							let zone = Intl.DateTimeFormat('en-US', {timeZone:zoneId, timeZoneName:'short'}).formatToParts(this.date).find?.(_ => _.type === "timeZoneName")?.value;
							let offset = Intl.DateTimeFormat('en-US', {timeZone:zoneId, timeZoneName:'shortOffset'}).formatToParts(this.date).find?.(_ => _.type === "timeZoneName")?.value;
							let calced = parseInt(offset.replace("GMT",""));
							let half = parseInt(offset.split(":")[1] ?? 0) / 60.0;
							if (Number.isNaN(calced)) {
								calced = 0;
							}
							if (!zone || !offset) throw new Error();
							let outOffset = calced > 0 ? calced+half : calced - half;
							return {"payload":{"offset": outOffset, "timezone": zone}}
						} catch {
							return Api.post(window.jsRoutes.controllers.WidgetsController.timezone(), {
								payload: {
									"timestamp": this.timestamp,
									"timezone": zoneId
								}
							})
								.then(r => {
									if (window.db) {
										window.db.set("timezones", {...r, timestamp: this.timestamp, timezone: zoneId})
									}
									return r
								})
						}
					})
					.then(r => [r.payload.offset, r.payload.timezone])
					.catch(() => [])
			} else {
				console.warn("window.db is not setup yet...");
				try {
					let zone = Intl.DateTimeFormat('en-US', {timeZone:zoneId, timeZoneName:'short'}).formatToParts(this.date).find?.(_ => _.type === "timeZoneName")?.value;
					let offset = Intl.DateTimeFormat('en-US', {timeZone:zoneId, timeZoneName:'shortOffset'}).formatToParts(this.date).find?.(_ => _.type === "timeZoneName")?.value;
					if (!zone || !offset) throw new Error();
					return [parseInt(offset.replace("GMT","")), zone]
				} catch {
					return Api.post(ApiRoutes.timezone(), {
						payload: {
							"timestamp": this.timestamp,
							"timezone": zoneId
						}
					})
						.then(r => {
							if (window.db) {
								window.db.set("timezones", {...r, timestamp: this.timestamp, timezone: zoneId})
							}
							return r
						})
						.then(r => [r.payload.offset, r.payload.timezone])
						.catch(() => [])
				}
			}
		} else {
			console.warn("No valid zoneId was provided", zoneId);
			return Promise.resolve([]);
		}
	}
}
