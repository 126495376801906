import PRODate from "helpers/proDate";
import {
	Button,
	Paper, Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TableRow,
	Typography
} from "@mui/material";
import {useEffect, useState} from "react";
import head from "lodash/head";
import last from "lodash/last";
import concat from "lodash/concat";
import flatMap from "lodash/flatMap";
import Api from "api/api";
import Promises from "helpers/promises";
import {DashboardRoutes} from "api/routes/Dashboard";
import PRODateRange from "helpers/proDateRange";
import {ArrowLeft, ArrowRight, Circle} from "@mui/icons-material";
import {DutyType} from "helpers/enum/dutyType";
import DataStore from "helpers/dataStore";
import {useNavigate} from "react-router-dom";

const Calendar = ()=>{
	const navigate = useNavigate();
	const viewingOffset = -(new Date().getTimezoneOffset() / 60);
	const today = PRODate.now().withOffset(viewingOffset).startOfDay();
	const [current, setCurrent] = useState(DataStore.Get.date("calendar", viewingOffset) || today);
	const [legs, setLegs] = useState([]);
	const [duties, setDuties] = useState([]);

	function generateDates(date) {
		let days = [];
		let first = date.firstOfMonth;
		let last = date.lastOfMonth.startOfDay();
		for (let i = first.dayOfWeek; i > 0; i--) {
			days.push(first.minus(i * 60 * 24));
		}
		for (let i = 1; i <= date.daysInMonth; i++) {
			days.push(date.withDay(i).startOfDay());
		}
		for (let i = 0; i < 6 - last.dayOfWeek; i++) {
			days.push(last.plus((i + 1) * 60 * 24));
		}
		return days;
	}

	const [dates, setDates] = useState(generateDates(current));

	const nextMonth = () => {
		const next = current.nextMonth();
		setCurrent(next);
		setDates(generateDates(next));
		DataStore.set("calendar", next.timestamp);
	}

	const lastMonth = () => {
		const prev = current.lastMonth();
		setCurrent(prev);
		setDates(generateDates(prev));
		DataStore.set("calendar", prev.timestamp);
	}

	useEffect(() => {
		let call = new AbortController();
		Api.post(DashboardRoutes.pilot.trips(), {
			"start": head(dates).startOfDay().timestamp,
			"end": last(dates).endOfDay().timestamp
		}, call).then(result => {
			let ops = new Promises();
			result.legs = concat(result.legs || [], flatMap(result.dispatches, function (d) {
				return d.legs
			}));
			(result.legs || []).forEach(function (leg) {
				leg.departure.time = new PRODate(leg.departure.time);
				leg.arrival.time = new PRODate(leg.arrival.time);
				ops.push(leg.departure.time.withZoneId(leg.departure.airport).then(function (d) {
					leg.departure.time = d;
					return d;
				}).catch(Api.flagFail));
				ops.push(leg.arrival.time.withZoneId(leg.arrival.airport).then(function (d) {
					leg.arrival.time = d;
					return d;
				}).catch(Api.flagFail));
			});
			(result.duties || []).forEach(function (duty) {
				duty.type = DutyType.parse(duty.type);
				duty.in = new PRODate(duty.in);
				if (duty.out) {
					duty.out = new PRODate(duty.out);
				}
				ops.push(duty.in.withZoneId(result.homebase).then(function (d) {
					duty.in = d;
				}, Api.flagFail));
				if (duty.out) {
					ops.push(duty.out.withZoneId(result.homebase).then(function (d) {
						duty.out = d;
					}, Api.flagFail));
				}
			});
			Promise.allSettled(ops).then(() => {
				result.legs?.forEach(d => {
					d.range = new PRODateRange(d.departure.time, d.arrival.time);
				});
				result.duties?.forEach(d => {
					d.range = new PRODateRange(d.in, d.out);
				});
				setLegs(result.legs || []);
				setDuties((result.duties || []));
			});
		}).catch(Api.silentFail);

		return () => {
			call.abort()
		}
	}, [dates]);

	return <TableContainer component={Paper}>
		<Stack direction={"row"}>
			<Button onClick={lastMonth}><ArrowLeft /></Button>
			<Button sx={{visibility:"hidden"}}>Today</Button>
			<Typography align={"center"} flexGrow={2} style={{display:"inline-grid", alignSelf:"center"}}>{current.format("MMM yyyy")}</Typography>
			<Button onClick={()=>{
				setCurrent(today);
				setDates(generateDates(today));
				DataStore.set("calendar", today.timestamp);
			}}>Today</Button>
			<Button onClick={nextMonth}><ArrowRight /></Button>
		</Stack>
		<Table stickyHeader>
			<TableHead>
				<TableRow sx={{ 'th': { width:"14.2%", padding:0 } }}>
					{PRODate.dayLabels().map(d => <TableCell key={d} align={"center"}>{d}</TableCell>)}
				</TableRow>
			</TableHead>
			<TableBody>
				{dates.reduce((out, d, ind)=>{
					const chunk = Math.floor(ind/7);
					if(!out[chunk]) {
						out[chunk] = [d];
					} else {
						out[chunk].push(d);
					}
					return out;
				}, []).map(r => <TableRow key={r[0].timestamp} sx={{ 'td': { width:"14.2%", padding:0, verticalAlign:"top"} }}>
					{r.map(d => {
						const dRange = new PRODateRange(d.startOfDay(), d.endOfDay());
						return <TableCell key={d.timestamp} align={"center"} sx={{
							color:d.month !== current.month ? "text.disabled" : "text.primary",
							bgcolor:d.month !== current.month ? "text.disabled" : null,
						}}>
							<Typography variant={"subtitle2"} sx={{bgcolor: d.isSameDay(today) ? "success.light" : null, color: d.isSameDay(today) ? "success.contrastText" : null}}>{d.format("dd")}</Typography>
							<Stack spacing={0} style={{minHeight:20}} alignItems={"center"}>
								{duties.filter(duty => dRange.intersects(duty.range)).map(d =>
									<Circle key={d._id.$oid} sx={{color: DutyType.duty().includes(d.type) ? "info.main" : "secondary.main"}} onClick={()=>{
										navigate("/duty/"+d._id.$oid);
									}} />)}
								{legs.filter(leg => dRange.intersects(leg.range)).map(l =>
									<Circle key={l._id.$oid} sx={{color: "success.main"}} onClick={()=>{
										navigate("/leg/"+l._id.$oid);
									}} />
								)}
							</Stack>
						</TableCell>
					})}
				</TableRow>)}
			</TableBody>
			<TableFooter>
				<TableRow>
					<TableCell colSpan={7}>
						<Stack direction={"row"} spacing={1} alignItems={"center"}>
							<Stack direction={"row"} spacing={2} alignItems={"center"}><Circle sx={{color: "info.main"}} /> Duty Record</Stack>
							<Stack direction={"row"} spacing={2} alignItems={"center"}><Circle sx={{color: "secondary.main"}} /> Non-Duty Record</Stack>
							<Stack direction={"row"} spacing={2} flexGrow={1} alignItems={"center"}><Circle sx={{color: "success.main"}} /> Leg Scheduled</Stack>
						</Stack>
					</TableCell>
				</TableRow>
			</TableFooter>
		</Table>
	</TableContainer>
}
export default Calendar;