import PRODate from "./proDate";
import isBoolean from "lodash/isBoolean";
import isArray from "lodash/isArray";
import isString from "lodash/isString";

const DataStore = {
	set: function (key, data) {
		localStorage.setItem(key, data);
	},
	get: function (key) {
		return localStorage.getItem(key);
	},
	remove: function (key) {
		localStorage.removeItem(key);
	},
	Set: {
		array: function(key, data) {
			DataStore.set(key, JSON.stringify(data));
		}
	},
	Get: {
		array: function (key) {
			try {
				let value = JSON.parse(DataStore.get(key));
				if (!value) return [];
				return isArray(value) ? value : isString(value) ? [value].filter(Boolean) : [];
			} catch (e) {
				return [];
			}
		},
		boolean: function (key, orElse) {
			try {
				let value = JSON.parse(DataStore.get(key));
				return isBoolean(value) ? value : orElse;
			} catch (e) {
				return orElse;
			}
		},
		int: function (key, orElse) {
			let value = Number.parseInt(DataStore.get(key));
			return Number.isInteger(value) ? value : orElse;
		},
		string: function (key, orElse) {
			return DataStore.get(key) || orElse;
		},
		date: function (key, offset) {
			let d = DataStore.Get.int(key);
			return d ? new PRODate(d, offset) : undefined;
		},
		object: function (key, orElse) {
			try {
				return JSON.parse(DataStore.get(key))
			} catch (e) {
				return orElse;
			}
		}
	}
};
export default DataStore;