import PRODate from "./proBaseDate";

export default class PRODateRange {
	constructor(start, end) {
		if (!(start instanceof PRODate)) {
			throw Error("Not a PRODate.")
		}
		if (!(end instanceof PRODate)) {
			throw Error("Not a PRODate.")
		}
		this.start = start;
		this.end = end;
		Object.freeze(this);
	}

	intersects(augend) {
		if (!(augend instanceof PRODateRange)) {
			return false
		}
		return this.start.isBetween(augend.start, augend.end) || this.end.isBetween(augend.start, augend.end) || augend.start.isBetween(this.start, this.end) || augend.end.isBetween(this.start, this.end);
	}

	intersectsRelative(augend) {
		if (!(augend instanceof PRODateRange)) {
			console.error("You are not comparing PRODateRange items together.");
			return false
		}
		return this.start.isBetweenRelative(augend.start, augend.end) ||
			this.end.isBetweenRelative(augend.start, augend.end) ||
			augend.start.isBetweenRelative(this.start, this.end) ||
			augend.end.isBetweenRelative(this.start, this.end);
	}

	static calendarDaysCovered(augend, addend) {
		if (augend.isSameOrAfterRelative(addend)) return 0;
		return Math.max(1, PRODateRange.daysBetween(augend, addend) + 1);
	}

	static daysBetween(augend, addend) {
		return Math.abs(PRODateRange.daysOffset(augend, addend));
	}

	static daysOffset(augend, addend) {
		let diff = 0;
		if (augend.year !== addend.year) {
			diff = addend.year - augend.year;
		}
		let daysInYear = augend.year % 400 === 0 || (augend.year % 100 !== 0 && augend.year % 4 === 0) ? 366 : 365;
		return (addend.dayOfYear + (diff * daysInYear)) - augend.dayOfYear;
	}
}