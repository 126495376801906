export const DutyType = {
	parse: function (id) {
		switch (id) {
			case 1:
				return this.CFT;
			case 2:
				return this.OCF;
			case 3:
				return this.Training;
			case 4:
				return this.Transit;
			case 5:
				return this.Sick;
			case 6:
				return this.Rest;
			case 7:
				return this.Vacation;
			case 8:
				return this.Office;
			case 9:
				return this.FD;
			case 10:
				return this.DAP;
			case 11:
				return this.FFD;
			case 12:
				return this.FAR;
			case 13:
				return this.Fatigue;
			default:
				return this.FFD;
		}
	},
	list: function () {
		return [this.CFT, this.OCF, this.Training, this.Transit, this.Sick, this.FAR, this.Rest, this.Vacation, this.Office, this.FD, this.DAP, this.FFD, this.Fatigue]
	},
	duty: function () {
		return [this.CFT, this.OCF, this.Training, this.Transit, this.Office, this.FD, this.DAP, this.FFD]
	},
	CFT: {id: 1, name: "Commercial Flight Time"},
	OCF: {id: 2, name: "Other Commercial Flight"},
	Training: {id: 3, name: "Training"},
	Transit: {id: 4, name: "Transit"},
	Sick: {id: 5, name: "Sick"},
	Rest: {id: 6, name: "Hard Day Off"},
	Vacation: {id: 7, name: "Vacation"},
	Office: {id: 8, name: "Office"},
	FD: {id: 9, name: "Flight Deck"},
	DAP: {id: 10, name: "Duty Assignment Period"},
	FFD: {id: 11, name: "Fit-For-Duty"},
	FAR: {id: 12, name: "Soft Day Off"},
	Fatigue: {id: 13, name: "Fatigue"},
};