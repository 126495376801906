import {useEffect, useState} from "react";
import Promises from "helpers/promises";
import Api from "api/api";
import {Api as ApiRoutes } from "api/routes/Api";
import {
	Card,
	CardContent, Divider, List, ListItemButton, ListItemText,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography
} from "@mui/material";
import {humanize} from "helpers/formatters";
import groupBy from "lodash/groupBy";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';

export const AirportInformation = ({icao}) => {
	const [airport, setAirport] = useState({});
	const [showWeather, setShowWeather] = useState(false);

	useEffect(() => {
		if (!icao) return;
		let promises = new Promises();
		const call1 = new AbortController();
		const call2 = new AbortController();
		const call3 = new AbortController();
		promises.push(Api.get(ApiRoutes.airportInformation(icao), call1).catch(Api.silentFail));
		promises.push(Api.get(ApiRoutes.weather(icao), call2).catch(Api.silentFail));
		promises.push(Api.get(ApiRoutes.airportNote(icao), call3).catch(Api.silentFail));
		Promise.all(promises).then(results => {
			setAirport(Object.assign(results[0] || {}, {
				"weather": (results?.[1]?.weather || {}),
				"note": results?.[2]?.note,
				"internalNote": results?.[2]?.internalNote
			}));
		})
		return () => {
			call1.abort();
			call2.abort();
			call3.abort();
		}
	}, [icao])

	return <div>
		<Stack spacing={2}>
			<Typography variant={"h3"}>{airport.name}</Typography>
			<address style={{margin:0}}>
				<Typography variant={"overline"} component={"div"}>{airport.address?.city},&nbsp;{airport.address?.state}</Typography>
				<Typography variant={"overline"} component={"div"}>{airport.address?.country}</Typography>
			</address>
			<div>Elevation: {airport.elevation}</div>

			{(airport.note ?? "").trim() === "" ? false : <Card>
				<CardContent>
					<Typography variant={"h5"}>Note</Typography>
					<pre>{airport.note}</pre>
				</CardContent>
			</Card>}
			{(airport.internalNote ?? "").trim() === "" ? false : <Card>
				<CardContent>
					<Typography variant={"h5"}>Internal Note</Typography>
					<pre>{airport.internalNote}</pre>
				</CardContent>
			</Card>}

			<Card>
				<CardContent>
					<Typography variant={"h5"} paddingBottom={2}>General Info</Typography>
					<Divider />
					<TableContainer>
						<Table>
							<TableBody>
								{airport.hours ? <TableRow>
									<TableCell>Hours</TableCell>
									<TableCell width={"100%"}>{airport.hours}</TableCell>
								</TableRow> : false}
								{airport.curfew && airport.curfew !== "" ?
									<TableRow>
										<TableCell>Curfew</TableCell>
										<TableCell width={"100%"}>{airport.curfew}</TableCell>
									</TableRow>
									: false}
								{airport.towerPhone && airport.towerPhone !== "" ?
									<TableRow>
										<TableCell>Tower Phone</TableCell>
										<TableCell width={"100%"}>{airport.towerPhone}</TableCell>
									</TableRow>
									: false}
								{airport.usage && airport.usage !== "" ?
									<TableRow>
										<TableCell>Usage</TableCell>
										<TableCell width={"100%"}>{airport.usage}</TableCell>
									</TableRow>
									: false}
								{airport.hasLandingFees && airport.hasLandingFees !== "" ?
									<TableRow>
										<TableCell>Has Landing Fees</TableCell>
										<TableCell width={"100%"}>{airport.hasLandingFees}</TableCell>
									</TableRow>
									: false}
							</TableBody>
						</Table>
					</TableContainer>
				</CardContent>
			</Card>

			{airport?.customs ?
				<Card>
					<CardContent>
						<Typography variant={"h5"} paddingBottom={2}>Customs</Typography>
						<Divider />
						<TableContainer>
							<Table>
								<TableBody>
									{Object.entries(airport.customs ?? {})?.map?.(([key, item]) =>
										<TableRow key={key}>
											<TableCell>{humanize(key)}</TableCell>
											<TableCell width={"100%"}>{(item instanceof Object) ? JSON.stringify(item).replaceAll(/["{}]/g, "").replaceAll(":", ": ").replaceAll(",", ", ") : item}</TableCell>
										</TableRow>
									)}
									{Object.entries(airport.customs?.hours ?? {})?.map?.(([key, item])=>
										<TableRow key={key}>
											<TableCell>{key}</TableCell>
											<TableCell width={"100%"}>{item}</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
					</CardContent>
				</Card>
				: false}

			<Card>
				<CardContent>
					<Typography variant={"h5"} paddingBottom={2}>Runways</Typography>
					<Divider />
					<TableContainer>
						<Table>
							<TableBody>
								{Object.entries(airport.runways ?? {})?.map?.(([name, rw])=>
									<TableRow key={"rw" + name} className="mono">
										<TableCell>{name}</TableCell>
										<TableCell width={"100%"}>{rw.length} x {rw.width}</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</CardContent>
			</Card>

			{airport.weather?.raw ?
				<Card>
					<CardContent>
						<Typography variant={"h5"} paddingBottom={2}>Weather</Typography>
						<Divider />
						<TableContainer>
							<Table>
								<TableBody>
									<TableRow>
										<TableCell>METAR</TableCell>
										<TableCell width={"100%"} onClick={() => {
											setShowWeather(!showWeather)
										}} style={{cursor:"pointer"}}>
											<Stack direction={"row"} alignItems={"center"}>
												<div style={{flexGrow:2, flexShrink:2}}>{airport.weather.raw}</div>
												{showWeather ? <KeyboardDoubleArrowUpIcon /> : <KeyboardDoubleArrowDownIcon />}
											</Stack>
										</TableCell>
									</TableRow>
									{showWeather ?
										<>
											<TableRow>
												<TableCell>Temp</TableCell>
												<TableCell width={"100%"}>{`${airport.weather.temp}˚C`}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell>Dew Point</TableCell>
												<TableCell width={"100%"}>{`${airport.weather.dewPoint}˚C`}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell>Visibility</TableCell>
												<TableCell width={"100%"}>{`${airport.weather.visibility} sm`}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell>Wind</TableCell>
												<TableCell width={"100%"}>{`${airport?.weather?.wind?.direction}˚ @ ${airport?.weather?.wind?.speed}kts`}</TableCell>
											</TableRow>
											{airport.weather.conditions.map((item, itr)=>
												<TableRow key={"cond_" + itr}>
													<TableCell>Conditions</TableCell>
													<TableCell width={"100%"}>{item.cover} @ {item.feet}ft</TableCell>
												</TableRow>
											)}
										</>
										: false}
								</TableBody>
							</Table>
						</TableContainer>
					</CardContent>
				</Card>
				: false}

			{airport?.fbos ?
				<>
					<Typography variant={"h5"}>FBOs</Typography>
					{Object.values(airport?.fbos ?? {})?.map?.(fbo =>
						<Card key={fbo.id}>
							<CardContent>
								<Typography variant={"h5"} paddingBottom={1}>{fbo.name}</Typography>
								<Stack paddingBottom={2}>
									{fbo.address ?
										<>
											<p>{fbo.address?.street}</p>
											<p>{fbo.address?.city},&nbsp;{fbo.address?.state}&nbsp;{fbo.address?.postalCode || fbo.address?.zip}</p>
										</>
										: undefined}
								</Stack>
								<Divider />
								<TableContainer>
									<Table>
										<TableBody>
											{(fbo.phones || []).map((i) =>
												<TableRow key={i}>
													<TableCell>Phone</TableCell>
													<TableCell width={"100%"}><a href={"tel:" + i}>{i}</a></TableCell>
												</TableRow>
											)}
											{fbo.fuels ?
												<TableRow>
													<TableCell>Fuel</TableCell>
													<TableCell width={"100%"}>{fbo.fuels}</TableCell>
												</TableRow>
												: false}
											{Object.entries(fbo.comms ?? {})?.map(([key, item])=>
												<TableRow key={key}>
													<TableCell>{key}</TableCell>
													<TableCell width={"100%"}>{item}</TableCell>
												</TableRow>
											)}
										</TableBody>
									</Table>
								</TableContainer>
							</CardContent>
						</Card>
					)}
				</>
			: false}

			{(airport.documents || []).length > 0 ?
				<>
					<Typography variant={"h5"}>Documents</Typography>
					{Object.entries(groupBy(airport.documents.sort((i, j) => i.code - j.code), (i)=> i.code)).map(([code, items]) =>
						<Card key={"group" + code}>
							<CardContent>
								<Typography variant={"h5"}>{code}</Typography>
							</CardContent>
							<List>
								{items.sort((i, j) => i.name.localeCompare(j.name, 'en', {sensitivity: 'base'})).map((doc, itr) =>
									<ListItemButton key={"doc" + itr} component={"a"} href={doc.url}>
										<ListItemText primary={doc.name} />
									</ListItemButton>
								)}
							</List>
						</Card>
					)}
				</>
				: false}
		</Stack>
	</div>
}