import React from 'react';
import ReactDOM from 'react-dom/client';
import App from 'App';
import reportWebVitals from 'reportWebVitals';
import {Provider} from "react-redux";
import store from 'data/store';
import {Authenticator} from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Amplify } from 'aws-amplify';

Amplify.configure({
	Auth: {
		Cognito: {
			userPoolId: "us-west-2_TagaQDKHW",
			userPoolClientId: process.env.REACT_APP_CLIENT_ID,
			identityPoolId: "us-west-2:d50be070-53f9-4cb7-96c5-93d6fe0c25f5"
		}
	}
});

ReactDOM.createRoot(document.getElementById('root'))
	.render(
		<React.StrictMode>
			<Authenticator.Provider>
				<Provider store={store}>
					<App/>
				</Provider>
			</Authenticator.Provider>
		</React.StrictMode>
	);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
