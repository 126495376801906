import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userReducer";
import operationReducer from "./operationReducer";
import currentDutyReducer from "./currentDutyReducer";

export default configureStore({
	reducer: {
		user: userReducer,
		operation: operationReducer,
		currentDuty: currentDutyReducer
	}
})