import {createSelector, createSlice} from "@reduxjs/toolkit";
import cloneDeep from "lodash/cloneDeep";
import {UserType} from "../helpers/enum/userType";

export const userSlice = createSlice({
	name: "user",
	initialState: {
		rights: {},
		userTypes: [],
	},
	reducers: {
		setUser: (state, action) => {
			return action.payload
		},
		setRights: (state, action) => {
			state.rights = action.payload || {};
		}
	}
})

export const { setUser , setRights} = userSlice.actions;

export const selectUser = createSelector(
	[state => state.user ],
	(user) => {
		const out = cloneDeep(user);
		if (out.userTypes) {
			out.userTypes = out.userTypes.map(r => UserType.parse(r))
		}
		return out
	}
);

export default userSlice.reducer;