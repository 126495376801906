import {
	Box,
	Button,
	Card, CardContent, Container,
	FormControl,
	FormControlLabel,
	FormLabel, Paper,
	Radio,
	RadioGroup,
	Stack,
	Typography
} from "@mui/material";
import API from "api/api";
import {TripRoutes} from "api/routes/TripRoutes";
import {useLoaderData, useNavigate, useParams} from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import {useState} from "react";
import Api from "api/api";
import {Loader} from "helpers/loader";

export const RiskAssessmentData = async ({params})=>{
	return API.get(TripRoutes.leg.riskAudit.get(params.id))
}
export const RiskAssessment = ()=>{
	const navigate = useNavigate();
	const data = useLoaderData();
	const params = useParams();
	const [answers, setAnswers] = useState(data?.answers ?? []);
	const [showLoader, setShowLoader] = useState(false);
	const form = data?.form ?? {};

	const change = (i, j, v)=> {
		setAnswers(p=> {
			let state = cloneDeep(p);
			set(state, `${i}.${j}`, parseInt(v));
			return state;
		});
	}

	const save = ()=> {
		setShowLoader(true);
		Api.post(TripRoutes.leg.riskAudit.save(params.id), {
			"_id": form._id,
			"answers": answers
		})
			.then(Api.flagSuccess)
			.then(() => {
				navigate(`/leg/${params.id}`);
			})
			.catch(Api.flagFail)
			.then(()=>{
				setShowLoader(false);
			})
	}

	const total = answers.flat().reduce((a, b) => a + b, 0);
	const rating = form?.ratings?.indexOf(form?.ratings?.findLast?.(p=> p <= total));
	const ratingColor = rating >= 2 ? "error.main" : (rating >= 1) ? "warning.main" : "success.main";

	return <Box>
		<Container sx={{bgcolor:"paper.main", paddingTop:2}}>
			<Button variant={"outlined"} onClick={()=> navigate(`/leg/${params.id}`)}>Back</Button>
		</Container>
		<Container sx={{paddingTop: 2}}>
			<Stack spacing={2}>
				{form.content.map((section, i) =>
					<Card key={"section-" + i}>
						<CardContent>
							<Typography variant={"h5"} paddingBottom={2}>{section.name}</Typography>
							<Stack>
								{section.content.map((question, j) =>
									<FormControl key={"question-" + i + "-" + j}>
										<FormLabel>{question.name}</FormLabel>
										<RadioGroup row value={(answers?.[i]?.[j] ?? 0).toString()} onChange={(v) => {
											change(i, j, v.target.value);
										}}>
											{question.content.map(answer =>
												<FormControlLabel key={answer.name} value={answer.points} control={<Radio/>}
																  label={answer.name}/>
											)}
										</RadioGroup>
									</FormControl>
								)}
							</Stack>
						</CardContent>
					</Card>
				)}
				<Container sx={{visibility: "hidden"}}>
					<Paper sx={{
						p: 2,
						bgcolor: "secondary.main",
						color: "secondary.contrastText",
						borderBottomLeftRadius: 0,
						borderBottomRightRadius: 0
					}}>
						<Typography variant={"h5"}>Total Points: {answers.flat().reduce((a, b) => a + b, 0)}</Typography>
					</Paper>
				</Container>
			</Stack>
			<Loader show={showLoader}/>
		</Container>
		<Box sx={{position: "fixed", bottom: 0, left: 0, right: 0}}>
			<Paper sx={{
				p: 2,
				bgcolor: "secondary.main",
				color: "secondary.contrastText",
				borderBottomLeftRadius: 0,
				borderBottomRightRadius: 0
			}}>
				<Stack direction={"row"}>
					<Typography variant={"h5"}>Total Points: <Typography variant={"h5"} component={"div"} display={"inline-grid"} alignItems={"center"} justifyItems={"center"} width={40} height={40} borderRadius={"50%"} bgcolor={ratingColor}>{total}</Typography></Typography>
					<div style={{flexGrow: 1}}/>
					<Button variant={"contained"} onClick={save}>Save</Button>
				</Stack>
			</Paper>
		</Box>
	</Box>
}