import Route from "./route";

export const Api = {

	getTools: () => new Route({method:"GET", url:`/api/operation/tools`}),

	fitForDuty: () => new Route({method:"POST", url:`/api/fitForDuty`}),

	dutyOut: () => new Route({method:"POST", url:`/api/dutyOut`}),

	timezone: () => new Route({method: "POST", url: `/api/widgets/timezone`}),

	listAircraft: () => new Route({method: "GET", url: "/api/aircraft/list"}),

	airportInformation: (icao) => new Route({method: "GET", url: `/api/airport/info/${icao}`}),

	weather: (icao) => new Route({method: "GET", url: `/utils/weather/${icao}`}),

	airportNote: (icao) => new Route({method: "GET", url: `/api/operation/airports/${icao}/note`}),

	airportSearch: (query)=> new Route({
		method: "GET", url: "/api/airport/search/" + encodeURIComponent((function (k, v) {
			return v
		})("query", query))
	}),

}