import { createSlice } from "@reduxjs/toolkit";

export const operationSlice = createSlice({
	name: "operation",
	initialState: {},
	reducers: {
		setOperation: (state, action) => {
			return action.payload
		},
		setTools: (state, action) => {
			state.tools = action.payload;
		}
	}
})

export const { setOperation, setTools } = operationSlice.actions;

export const selectOperation = state => state.operation;

export default operationSlice.reducer;