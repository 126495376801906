import {Autocomplete, TextField} from "@mui/material";
import {useEffect, useState} from "react";
import API from "api/api";
import {Api} from "api/routes/Api";

export const AirportFinder = ({name, value, onChange})=> {
	const [airports, setAirports] = useState([]);
	const [term, setTerm] = useState("");

	useEffect(()=>{
		if (term.length < 3) return;
		const call = new AbortController();
		API.get(Api.airportSearch(term), call)
			.then(result=> {
				setAirports(result.airports);
			})
			.catch(()=> setAirports([]))
		return ()=>{
			call.abort();
		}
	}, [term])

	return <Autocomplete renderInput={(p)=> <TextField {...p} label={name} />}
				  options={airports.map(i => ({id:i.icao, label:`${i.icao} - ${i.name}`}))} value={value} inputValue={term}
				  onChange={(e,v) => onChange(v.id)}
				  onInputChange={(e,t)=> setTerm(t)}
	/>
}