import {useLoaderData, useNavigate} from "react-router-dom";
import has from "lodash/has";
import Api from "api/api";
import PRODate from "helpers/proDate";
import {UserRoutes} from "api/routes/UserRoutes";
import {
	Box,
	Button,
	Container,
	Stack, Table, TableBody, TableCell, TableContainer, TableRow,
	Typography
} from "@mui/material";
import {DutyType} from "helpers/enum/dutyType";

export const DutyViewData = async ({params}, userId)=>{
	return Api.get(UserRoutes.dutyTime.get(userId, params.id))
		.then(results => {
			results.duty.type = DutyType.parse(results.duty.type);
			if (has(results.duty, "in")) {
				results.duty.in = new PRODate(results.duty.in);
			}
			if (has(results.duty, "out")) {
				results.duty.out = new PRODate(results.duty.out);
			}
			return results;
		})
		.catch(Api.silentFail);
}

const DutyView = ()=>{
	const navigate = useNavigate();
	const data = useLoaderData();
	const duty = data?.duty ?? {};

	return <Box>
		<Box position={"static"} sx={{paddingTop:1, paddingBottom:1}}>
			<Container>
				<Button variant={"outlined"} onClick={()=> navigate(-1)}>Back</Button>
			</Container>
		</Box>

		<Container sx={{padding:2}}>
			<Stack spacing={2}>
				<Typography variant={"h5"} align={"center"}>View Duty Record</Typography>
				<TableContainer>
					<Table>
						<TableBody>
							<TableRow>
								<TableCell sx={{borderTop:1, whiteSpace:"nowrap"}}>Duty Type</TableCell>
								<TableCell sx={{borderTop:1, width:"100%"}}>{duty?.type?.name}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>On</TableCell>
								<TableCell>{duty?.in?.format?.()}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Off</TableCell>
								<TableCell>{duty?.out?.format?.() ?? "Open"}</TableCell>
							</TableRow>
							{[DutyType.Training.id, DutyType.OCF.id, DutyType.FD.id, DutyType.CFT.id].includes(duty.type) ? <>
								<TableRow>
									<TableCell>Aircraft</TableCell>
									<TableCell>{duty?.craft?.tailNumber ?? "Unassigned"}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>Night Landings</TableCell>
									<TableCell>{duty?.landings ?? 0}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>Flight Time</TableCell>
									<TableCell>{duty?.flightTime ?? 0}</TableCell>
								</TableRow>
							</> : false}
							{duty.type === DutyType.DAP ?
								<TableRow>
									<TableCell>Aircraft</TableCell>
									<TableCell>{duty?.craft?.tailNumber ?? "Unassigned"}</TableCell>
								</TableRow>
							: false}
							{duty.type === DutyType.Transit ?
								<TableRow>
									<TableCell>To Airport</TableCell>
									<TableCell>{duty?.airport ?? "N/A"}</TableCell>
								</TableRow>
							: false}
						</TableBody>
					</Table>
				</TableContainer>
			</Stack>
		</Container>
	</Box>
}
export default DutyView;