import {useLoaderData, useNavigate} from "react-router-dom";
import {Box, Button, Container, Stack} from "@mui/material";
import {useRef} from "react";

export const ViewerData = async ({request}) => {
	const url = new URL(request.url);
	const search = new URLSearchParams(url.search);
	return fetch(search.get("blob"))
		.then(r => r.blob())
		.then(blob => {
			const fileName = search.get("name") ?? "LevelFlight File";
			return {
				file: new File([blob], fileName + "." + (blob.type.split("/")[1]), {type: blob.type}),
				src: search.get("blob")
			}
		})
		.catch(()=>{
			window.history.back()
		})
}

export const View = ()=> {
	const navigate = useNavigate();
	const data = useLoaderData();
	const frame = useRef();
	const file = data?.file;
	const src = data?.src;

	const printPage = ()=>{
		const is_safari = navigator.userAgent.toLowerCase().indexOf('safari/') > -1;
		if (frame.current?.contentWindow?.print) {
			if (is_safari) {
				setTimeout(()=>frame.current?.contentWindow?.print?.(), 500);
			} else {
				frame.current?.contentWindow?.print?.();
			}
		} else if (frame.current?.print) {
			if (is_safari) alert("frame print");
			frame.current?.print?.();
		} else {
			if (is_safari) alert("window print");
			window.print();
		}
	}

	return <div style={{height:"calc(100vh - 63px)"}}>
		<Box position={"static"} sx={{paddingTop:1, paddingBottom:1}}>
			<Container>
				<Stack direction={"row"} spacing={2}>
					<Button variant={"outlined"} onClick={()=> navigate(-1)}>Back</Button>
					<Button variant={"contained"} onClick={printPage}>Print</Button>
					{navigator.share ?
						<Button variant={"contained"} onClick={()=>{
							try {
								navigator.share({files: [file]}).catch(()=> {});
							} catch (e) {
								// do nothing
							}
						}}>Share</Button>
					: false}
				</Stack>
			</Container>
		</Box>
		<iframe ref={frame} title={"Viewer"} src={src} width={"100%"} height={"100%"} style={{border:0}} />
	</div>
}