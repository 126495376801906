export default class Promises extends Array {
	push(...items) {
		let wrapped = items.map(c =>
			new Promise(s => {
				if (c) {
					c.then(r => {
						s(r);
					})
				} else {
					s(c);
				}
			})
		)
		super.push(...wrapped);
	}
}