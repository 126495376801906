import {Backdrop, CircularProgress, Stack, Typography} from "@mui/material";

export const Loader = ({show, text})=>{
	return <Backdrop
		sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
		open={show}
		onClick={() => {
		}}
	>
		<Stack>
			<CircularProgress color="inherit" sx={{margin:"0 auto"}}/>
			<Typography variant={"overline"}>{text}</Typography>
		</Stack>
	</Backdrop>
}