import {useLayoutEffect, useMemo, useRef, useState, forwardRef, useContext} from "react";
import PRODate from "helpers/proDate";
import noop from "lodash/noop";
import range from "lodash/range";
import union from "lodash/union";
import nth from "lodash/nth";
import initial from "lodash/initial";
import clamp from "lodash/clamp";
import styles from "./style.module.css";
import {
	Box,
	Card,
	Input,
	Stack,
	TextField,
	Tooltip,
	Typography
} from "@mui/material";
import {ArrowCircleLeftOutlined, ArrowCircleRightOutlined} from "@mui/icons-material";
import {DatePickerFieldContext} from "App";

export const chunk = function (array, size) {
	let R = [];
	for (let i = 0; i < array.length; i += size)
		R.push(array.slice(i, i + size));
	return R;
};

export const PRODatePicker = ({type="date", value, onChange=noop, debug}) => {
	const {field, setField} = useContext(DatePickerFieldContext);
	const yearInput = useRef();
	const monthInput = useRef();
	const dayInput = useRef();
	const [yearFormat, setYearFormat] = useState("yyyy");
	const [showCal, setShowCal] = useState(false);
	const _selected = (value instanceof PRODate) ? value : PRODate.now();
	const today = PRODate.now().withOffset(_selected.offset);
	const useNative = useMemo(()=>
		(function (a) {
			if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series([46])0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br([ev])w|bumb|bw-([nu])|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do([cp])o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly([-_])|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-([mpt])|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c([- _agpst])|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac([ \-/])|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja([tv])a|jbro|jemu|jigs|kddi|keji|kgt([ /])|klon|kpt |kwc-|kyo([ck])|le(no|xi)|lg( g|\/([klu])|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t([- ov])|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30([02])|n50([025])|n7(0([01])|10)|ne(([cm])-|on|tf|wf|wg|wt)|nok([6i])|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan([adt])|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c([-01])|47|mc|nd|ri)|sgh-|shar|sie([-m])|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel([im])|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c([- ])|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(a.substring(0, 4))) {
				try {
					let test = document.createElement("input");
					test.type = "date";
					return test.type === "date";
				} catch (e) {
					return false;
				}
			}
		})(navigator.userAgent || navigator.vendor || window.opera)
	, []);

	if (debug) {
		console.info("PRODatePicker::", value);
	}

	useLayoutEffect(() => {
		if (yearInput.current) {
			let width = yearInput.current.getBoundingClientRect().width;
			if (yearFormat === "yyyy" && width < 40) {
				setYearFormat("yy")
			}
			if (yearFormat === "yy" && width > 40) {
				setYearFormat("yyyy")
			}
		}
	}, [yearInput, yearFormat]);

	const dateClicked = (date) => {
		if (!PRODate.equals(value, date)) {
			if (date instanceof PRODate) {
				onChange(date);
			} else {
				onChange(null);
			}
		}
		setShowCal(false);
	}

	const onFocus = (ev) => {
		let t = ev.target;
		if (dayInput.current === ev.target) {
			t = dayInput;
			setField("dayInput");
		}
		if (monthInput.current === ev.target) {
			t = monthInput;
			setField("monthInput");
		}
		if (yearInput.current === ev.target) {
			t = yearInput;
			setField("yearInput");
		}
		setShowCal(true);
		setTimeout(()=> {
			t.current.focus();
			t.current.select();
		}, 0);
	}

	const onBlur = (ev) => {
		onFieldChange();
		setTimeout(()=>{
			if (![dayInput.current, monthInput.current, yearInput.current].includes(document.activeElement)) {
				setShowCal(false);
			}
		}, 100);
	}

	const onNativeChange = (ev) => {
		let [year, month, day] = ev.target.value.split("-");
		year = parseInt(year) || new Date().getFullYear();
		month = (parseInt(month) || 1) - 1;
		day = parseInt(day) || 1;
		let out;
		if (value) {
			try {
				let date = value.withYear(year).withMonth(month).withDay(day);
				out = date || value;
			} catch (e) {
				console.warn("date error", e)
			}
		} else {
			try {
				out = new PRODate().withYear(year).withMonth(month).withDay(day);
			} catch (e) {
				console.warn("date error", e)
			}
		}
		onChange(out);
	}

	const onFieldChange = () => {
		// TODO: Why was I doing a timezone offset? Maybe for when the picker is in local?
		// let offset = new Date().getTimezoneOffset() / -60;
		// const now = PRODate.now().withOffset(offset);
		let date = (_selected instanceof PRODate) ? _selected : PRODate.now();

		let _year;
		if (yearInput.current && yearInput.current.value) {
			let out = parseInt(yearInput.current.value);
			if (out > 1000) {
				_year = out;
			} else if (out < 100) {
				_year = 2000+out;
			} else {
				_year = date.year;
			}
		} else {
			_year = date.year;
		}

		let parts = [
			(dayInput.current && dayInput.current.value ? parseInt(dayInput.current.value) : date.day),
			(monthInput.current && monthInput.current.value ? parseInt(monthInput.current.value) - 1 : date.month),
			_year
		];

		try {
			date = date.withDay(1).withYear(parts[2]).withMonth(parts[1]).withDay(parts[0]);
		} catch (e) {
			// Do nothing
		}

		if (!PRODate.equals(value, date)) {
			onChange(date);
		}
	}

	let day, month, year;
	try {
		day = value.format("dd");
		month = value.format("MM");
		year = value.format(yearFormat);
	} catch (e) {
		// Do nothing
	}

	const nativeValue = value?.format ? value.format(type === "month" ? "yyyy-MM" : "yyyy-MM-dd") : null;

	useLayoutEffect(() => {
		if (![dayInput.current, monthInput.current, yearInput.current].includes(document.activeElement)) {
			switch (field) {
				case "monthInput":
					dayInput.current.focus();
					setField(null);
					break;
				case "dayInput":
					yearInput.current.focus();
					setField(null);
					break;
				default:
					// Do nothing
			}
		}
	}, [value]);

	return useNative ?
	<TextField label={"Date"} type={type} value={nativeValue} onChange={onNativeChange} placeholder={nativeValue ? null : "Select Date"} style={{minWidth: 140}} />
	:
		<Stack>
			<TextField label={"Date"} value={value} InputProps={{
				inputComponent: forwardRef(({className, ...props}, ref) => {
					return <Stack direction={"row"} alignItems={"center"}>
						<Input className={className} style={{width: 20}}
							   inputProps={{style: {textAlign: "center", padding: 0}}}
							   inputRef={monthInput} defaultValue={month} onFocus={onFocus} onBlur={onBlur}/>
						<span>/</span>
						<Input className={className} style={{width: 20}}
							   inputProps={{style: {textAlign: "center", padding: 0}}}
							   inputRef={dayInput} defaultValue={day} onFocus={onFocus} onBlur={onBlur}/>
						<span>/</span>
						<Input key={"year_" + yearFormat} className={className} style={{width: 40}}
							   inputProps={{style: {textAlign: "center", padding: 0}}}
							   inputRef={yearInput} defaultValue={year} onFocus={onFocus} onBlur={onBlur}/>
					</Stack>
				})
			}} />
			{showCal ?
				<div style={{position:"relative"}}>
					<PROCalendar
						key={_selected.timestamp}
						selected={_selected}
						today={today}
						changed={dateClicked}
						debug={debug}
					/>
				</div>
			: false}
		</Stack>
}

export const PROCalendar = ({selected, today, changed, debug}) => {
	const offset = new Date().getTimezoneOffset() / -60;
	const [showing, setShowing] = useState(selected || PRODate.now().withOffset(offset));

	const monthLength = showing.daysInMonth;
	const fill = range(-showing.firstOfMonth.dayOfWeek, 0);
	let days = range(1, monthLength + 1);
	if (fill.length > 0) {
		days = union(fill, days);
	}
	const breakdown = chunk(days, 7);
	const shownHasSelected = (selected instanceof PRODate && showing.year === selected.year && showing.month === selected.month) ? selected.day : false;
	const shownHasToday = (showing.year === today.year && showing.month === today.month) ? today.day : false;

	const lastFocus = document.activeElement;

	const changeShowing = (showNext) => {
		const next = (showNext) ? showing.nextMonth() : showing.lastMonth();
		if (debug) {
			console.info("show next", next);
		}
		setShowing(next);
		lastFocus.focus();
	}

	if (debug) {
		console.info("PROCalendar", showing, selected);
	}

	return <Card variant={"outlined"} className={styles.calendar}>
		<Stack direction={"row"} alignItems={"center"} sx={{paddingLeft:1, paddingRight:1}}>
			<ArrowCircleLeftOutlined onClick={() => {
				changeShowing(false)
			}}/>
			<Typography variant={"overline"} style={{flexGrow:1, textAlign:"center"}}>{PRODate.monthLabels()[showing.month]} {showing.year}</Typography>
			<ArrowCircleRightOutlined style={{justifySelf: "end"}} onClick={() => {
				changeShowing(true)
			}}/>
		</Stack>
		<Box display={"grid"} gridTemplateColumns="repeat(7, 1fr)" className="text-center">
			{PRODate.dayLabels().map(function (day) {
				return <Typography variant={"body2"} key={day}>{day}</Typography>;
			})}
			{breakdown.map(row =>
				row.map(day =>
					<Typography variant={"overline"} key={"day_" + day} className={styles.day} onClick={() => {
						let date = (selected || showing).withDay(1).withYear(showing.year).withMonth(showing.month).withDay(day);
						changed(date);
					}}
						sx={{
							border: 2,
							borderColor: (day === shownHasToday) ? "success.light" : "transparent",
							bgcolor: (day === shownHasSelected) ? "primary.light" : "",
							color: (day === shownHasSelected) ? "primary.contrastText" : "",
						}}>
						{(day > 0) ? day : false}
					</Typography>
				)
			)}
		</Box>
	</Card>
}

export const PROTimePicker = ({value, onChange=noop, allowLocal, allowZulu}) => {

	return <TextField key={value?.format?.("HH:mmZ") ?? "-"}
		label={"Time"}
		className="text-center"
		style={{width: 110}}
		defaultValue={value?.format?.("HH:mm") ?? ""}
		onBlur={(f) => {
			const mins = Calendar.ProcessHours(f.target.value);
			onChange(value.startOfDay().plus(mins));
			f.target.value = value?.format?.("HH:mm") ?? "";
		}}
		onFocus={(f) => {
			f.target.value = value?.format?.("HHmm") ?? "";
			setTimeout(()=>f.target?.select?.(), 0);
		}}
		InputProps={{
			endAdornment: <Stack style={{lineHeight:1, paddingLeft:2}}>
				{(value instanceof PRODate) ? <Typography variant={"overline"} sx={{lineHeight:"0.75rem"}}>{value.format("WWW")}</Typography> : null}
				{allowLocal ?
					<Tooltip className={"centered"} title={"Click to view this date in your computer's timezone"}>
						<button className={"text-primary"} onClick={() => {
							const d = new Date();
							if (value) {
								onChange(value.withOffset(-d.getTimezoneOffset() / 60))
							}
						}} style={{fontSize:"7pt"}}>
							<div className={"text-white bg-primary text-center"} style={{borderRadius:4,padding:"0 2px"}}>{(value instanceof PRODate) ? value.timezone : "Z"}</div>
						</button>
					</Tooltip>
					:
					allowZulu ?
						<Tooltip className={"centered"} title={"Click to view this date in Zulu"}>
							<button className={"text-primary"} onClick={() => {
								if (value) {
									onChange(value.withOffset(0))
								}
							}} style={{fontSize:"7pt"}}>
								<div className={"text-white bg-primary text-center"} style={{borderRadius:4,padding:"0 2px"}}>{(value instanceof PRODate) ? value.timezone : "Z"}</div>
							</button>
						</Tooltip>
						:
						(value instanceof PRODate) ? value.timezone : "Z"
				}
			</Stack>
		}}
	/>
}

export const PRODateTimePicker = ({className, showing, value, style, append, allowLocal, allowZulu, onChange=noop, debug}) => {
	const change = (date) => {
		if (debug) {
			console.info("PRODateTimePicker::change", date);
		}
		if (!PRODate.equals(value, date)) {
			if (date instanceof PRODate) {
				onChange(date);
			} else {
				onChange(null);
			}
		}
	}

	return <Box display={"grid"} className={className || ""} gridTemplateColumns="auto auto 1fr" style={style} gap={1}>
		<PRODatePicker value={value} onChange={change} showing={showing} debug={debug} />
		<PROTimePicker value={value} onChange={change} debug={debug} allowLocal={allowLocal} allowZulu={allowZulu} />
		<div>{append}</div>
	</Box>
}

const Calendar = {
	ProcessHours: function (val) {
		let ones = parseInt(nth(val, -1)) || 0;
		let tens = (parseInt(nth(val, -2)) || 0) * 10;
		let hParts = initial(initial(val));
		let hours = (parseInt(hParts.join("")) || 0) * 60;
		return clamp(hours + tens + ones, 0, 1439);
	}
};