import {useSelector} from "react-redux";
import {selectUser} from "data/userReducer";
import {Button, Container, Stack} from "@mui/material";
import DutyBlock from "pages/dashboard/dutyBlock";
import Calendar from "pages/dashboard/calendar";
import {useNavigate} from "react-router-dom";

const Dashboard = () => {
	const navigate = useNavigate();
	const user = useSelector(selectUser);

	return user.fullName ?
		<Container maxWidth="lg" sx={{mt: 2}}>
			<Stack spacing={2}>
				<DutyBlock />
				<Button variant={"contained"} onClick={()=> navigate("/create")}>Create new Leg</Button>
				<Calendar />
			</Stack>
		</Container>
		:
		false
}
export default Dashboard;