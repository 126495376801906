import {
	Alert, Box,
	Button,
	Container,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Stack, TextField,
	ToggleButton, ToggleButtonGroup,
	Typography
} from "@mui/material";
import {useState} from "react";
import API from "api/api";
import { Api } from "api/routes/Api";
import PRODate from "helpers/proDate";
import {PRODateTimePicker} from "helpers/datePickers";
import {AirportFinder} from "helpers/airportFinder";
import {SeatPosition} from "helpers/enum/seatPosition";
import {Loader} from "helpers/loader";
import {TripRoutes} from "api/routes/TripRoutes";
import {useSelector} from "react-redux";
import {selectUser} from "data/userReducer";
import {useLoaderData, useNavigate} from "react-router-dom";

export const CreateLegData = async () => {
	return API.get(Api.listAircraft())
}

export const CreateLeg = ()=>{
	const navigate = useNavigate();
	const data = useLoaderData();
	const aircraft = data?.aircraft ?? [];
	const [craft, setCraft] = useState(null);
	const [time, setTime] = useState(PRODate.now);
	const [departure, setDeparture] = useState(null);
	const [arrival, setArrival] = useState(null);
	const [flightId, setFlightId] = useState(null);
	const [hasPax, setHasPax] = useState(true);
	const [seat, setSeat] = useState(SeatPosition.PIC.id);
	const [showLoader, setShowLoader] = useState(false);
	const [loaderText, setLoaderText] = useState("Preparing your data");
	const [alert, setAlert] = useState(null);
	const user = useSelector(selectUser);

	const save = ()=>{
		setShowLoader(true);
		setAlert(null);
		API.post(TripRoutes.trip.create(), {
			"aircraft": craft,
			"pairs": [{
				"date": time.timestamp,
				"from": departure,
				"to": arrival,
				"pax": hasPax ? 1 : 0,
			}]
		})
			.then(result => {
				setLoaderText("Assigning your seat position");
				return API.post(TripRoutes.leg.saveAssignment(result.dispatch.legs[0].$oid, "pilots"), {
					"user": {"_id": user._id},
					"seat": seat,
				})
					.then(() => result.dispatch)
			})
			.then(trip => {
				if (!flightId || !(flightId?.trim?.()?.length > 0)) return trip;
				return API.post(TripRoutes.trip.save(), {
					"_id": trip._id,
					"path": "flightId",
					"value": flightId
				}).then(()=> trip)
			})
			.then(trip => {
				setLoaderText("Releasing the leg");
				return API.post(TripRoutes.leg.markLegAsReleased(trip._id.$oid), {"legs": trip.legs.map(i => i?.$oid ?? i)})
					.then(() => trip)
			})
			.then(trip => {
				setLoaderText("Completed!");
				setShowLoader(false);
				navigate("/leg/"+trip.legs[0].$oid);
			})
			.catch(e=>{
				setAlert({level: "error", text: e.message});
			})
			.then(()=>{
				setShowLoader(false);
			})
	}

	return <Box>
		<Box position={"static"} sx={{paddingTop:1, paddingBottom:1}}>
			<Container>
				<Button variant={"outlined"} onClick={()=> navigate(-1)}>Back</Button>
			</Container>
		</Box>
		<Container>
			{alert ? <Alert severity={alert.level}>{alert.text}</Alert> : false}
			<Typography variant={"h5"} paddingBottom={2}>Create a new Leg</Typography>
			<Stack spacing={2}>
				<FormControl fullWidth>
					<InputLabel>Aircraft</InputLabel>
					<Select value={craft ?? ""} label={"Aircraft"} onChange={v=> setCraft(v.target.value)}>
						{aircraft.map(c =>
							<MenuItem key={c._id.$oid} value={c._id.$oid}>{c.tailNumber} {c.type.name}</MenuItem>
						)}
					</Select>
				</FormControl>
				<TextField label={"Flight ID (Optional)"} value={flightId} onChange={ev=> setFlightId(ev.target.value)} />
				<Stack direction={"row"} spacing={2} alignItems={"center"}>
					<InputLabel>Time</InputLabel>
					<PRODateTimePicker value={time} onChange={setTime}/>
				</Stack>
				<AirportFinder name={"Departure"} value={departure} onChange={setDeparture} />
				<AirportFinder name={"Arrival"} value={arrival} onChange={setArrival} />
				<Stack direction={"row"} spacing={2} alignItems={"center"}>
					<InputLabel>Has Passengers</InputLabel>
					<ToggleButtonGroup value={hasPax.toString()} exclusive onChange={e => {
						setHasPax(e.target.value === "true")
					}}>
						<ToggleButton value="true">Yes</ToggleButton>
						<ToggleButton value="false">No</ToggleButton>
					</ToggleButtonGroup>
				</Stack>
				<Stack direction={"row"} spacing={2} alignItems={"center"}>
					<InputLabel>Seat Position</InputLabel>
					<ToggleButtonGroup value={seat} exclusive onChange={e => {
						setSeat(parseInt(e.target.value));
					}}>
						{SeatPosition.pilots().map(s => <ToggleButton key={s.id} value={s.id}>{s.name}</ToggleButton>)}
					</ToggleButtonGroup>
				</Stack>
				<Button variant="contained" onClick={save}>Save</Button>
			</Stack>
			<Loader show={showLoader} text={loaderText} />
		</Container>
	</Box>
}