class Route {
	constructor(r) {
		this.method = r.method;
		this.url = r.url;
		this.root = process.env.REACT_APP_API_SERVER;
	}

	absoluteURL = ()=>{ return 'https://' + this.root + this.url }
	webSocketURL = ()=>{ return 'wss://' + this.root + this.url }
}

export default Route;