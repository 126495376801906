import Route from "./route";

export const DashboardRoutes = {
	"pilot": {
		"list": ()=> new Route({method: "GET", url: "/api/dashboard/pilot"}),
		"trips": ()=> new Route({method: "POST", url: "/api/dashboard/pilot/trips"})
	},
	"attendant": {
		"trips": ()=> new Route({method: "POST", url: "/api/dashboard/attendant/trips"})
	}
}