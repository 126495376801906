import {IconButton, ListItemText, Menu, MenuItem, Toolbar, Typography} from "@mui/material";
import {Menu as MenuIcon} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectOperation} from "../../data/operationReducer";
import {useState} from "react";
import {useAuthenticator} from "@aws-amplify/ui-react";
import {AppBar as AppBarMUI} from "@mui/material";

export const AppBar = ()=> {
	const navigate = useNavigate();
	const operation = useSelector(selectOperation);
	const [anchorEl, setAnchorEl] = useState(null);
	const {user, signOut} = useAuthenticator((context) => [context.user]);

	return <AppBarMUI position={"sticky"}>
		<Toolbar disableGutters>
			<Typography variant={"h4"} component={"h1"} onClick={()=> navigate("/") } sx={{paddingLeft:1}}>
				{operation.name ? operation.name : "LevelFlight"}
			</Typography>
			<div style={{flexGrow: 2}}/>
			<div>
				<IconButton size={"large"} aria-controls={"menu-appbar"} onClick={(ev)=>{ setAnchorEl(ev.target) }}>
					<MenuIcon />
				</IconButton>
				<Menu id={"menu-appbar"} anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)}
					  onClose={()=>{ setAnchorEl(null) }}>
					<ListItemText>{user.firstName} {user.lastName}</ListItemText>
					<MenuItem onClick={()=>{ navigate("/") }}>
						Dashboard
					</MenuItem>
					<MenuItem onClick={signOut}>
						Sign Out
					</MenuItem>
				</Menu>
			</div>
		</Toolbar>
	</AppBarMUI>
}