export const LegStatus = function () {
	class Value {
		constructor(id, name, weight) {
			this.id = id;
			this.name = name;
			this.weight = weight
		}

		isAtOrAbove(apperand) {
			return apperand.weight <= this.weight;
		}

		isAtOrBelow(apperand) {
			return apperand.weight >= this.weight;
		}
	}

	let obj = {
		parse: function (id) {
			switch (id) {
				case 0:
					return this.Open;
				case 1:
					return this.Released;
				case 2:
					return this.Flown;
				case 3:
					return this.Closed;
				case 4:
					return this.DutyOn;
				case 5:
					return this.Pseudo;
				case 6:
					return this.Pending;
				default:
					return this.Open;
			}
		}
	}

	obj.Open = new Value(0, "Open", 0);
	obj.Released = new Value(1, "Released", 1);
	obj.DutyOn = new Value(4, "DutyOn", 1.5);
	obj.Flown = new Value(2, "Flown", 2);
	obj.Closed = new Value(3, "Closed", 3);
	obj.Pseudo = new Value(5, "Pseudo", 3);
	obj.Pending = new Value(6, "Pending Release", 0.5);

	return obj;
}();