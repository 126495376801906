import { createSlice } from "@reduxjs/toolkit";
import PRODate from "../helpers/proDate";
import has from "lodash/has";

export const currentDutySlice = createSlice({
	name: "currentDuty",
	initialState: { },
	reducers: {
		setCurrentDuty: (state, action) => {
			if (has(action, "payload.start")) {
				action.payload.start = new PRODate(action.payload.start);
			}
			if (has(action, "payload.end")) {
				action.payload.end = new PRODate(action.payload.end);
			}
			if (has(action, "payload.mot")) {
				action.payload.mot = new PRODate(action.payload.mot);
			}
			if (has(action, "payload.cmot")) {
				action.payload.cmot = new PRODate(action.payload.cmot);
			}
			return action.payload
		}
	}
})

export const { setCurrentDuty } = currentDutySlice.actions;

export const selectCurrentDuty = state => state.currentDuty;

export default currentDutySlice.reducer;