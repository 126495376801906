export const Part = {
	parse: function(id){
		switch(id) {
			case 1: return this.Part91;
			case 2: return this.Part121;
			case 3: return this.Part125;
			case 4: return this.Part135;
			default: return this.Part91;
		}
	},
	byPurpose: function(purpose, hasPax) {
		if ([4,5,6,8,9].includes(purpose.id) || !hasPax) return this.Part91;
		// TODO: This needs to be the store value instead of a window var.
		return window.LF.Operation.part;
	},
	Part91:{id:1, name:"Part 91"},
	Part121:{id:2, name:"Part 121"},
	Part125:{id:3, name:"Part 125"},
	Part135:{id:4, name:"Part 135"}
};