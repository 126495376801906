export const SeatPosition = {
	parse: function (id) {
		switch (id) {
			case 2:
				return this.PIC;
			case 3:
				return this.SIC;
			case 4:
				return this.RPIC;
			case 5:
				return this.RSIC;
			case 6:
				return this.FAL;
			case 7:
				return this.FA;
			case 8:
				return this.PAXL;
			case 9:
				return this.PAX;
			case 10:
				return this.MECH;
			case 11:
				return this.SCREEN;
			default:
				return this.NA;
		}
	},
	NA: {id: -1, name: "No Assignment"},
	PIC: {id: 2, name: "PIC"},
	SIC: {id: 3, name: "SIC"},
	RPIC: {id: 4, name: "R-PIC"},
	RSIC: {id: 5, name: "R-SIC"},
	FAL: {id: 6, name: "Lead Attendant"},
	FA: {id: 7, name: "Attendant"},
	PAXL: {id: 8, name: "Lead Passenger"},
	PAX: {id: 9, name: "Passenger"},
	MECH: {id: 10, name: "Mechanic"},
	SCREEN: {id: 11, name: "Screener"},
	pilots: function(){ return [this.PIC, this.SIC, this.RPIC, this.RSIC] }
};