import Route from "./route";

export const UserRoutes = {
	"search": function() {
		return new Route({
			method: "POST",
			url: "/api/user/search"
		})
	},
	"dutyTime": {
		"get": function(id0, id1) {
			return new Route({
				method: "GET",
				url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/duty/" + encodeURIComponent((function (k, v) {
					return v
				})("duty", id1))
			})
		},
	},
	"swap": {
		"request": function(id0, id1) {
			return new Route({
				method: "POST",
				url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/duty/" + encodeURIComponent((function (k, v) {
					return v
				})("duty", id1)) + "/swap/request"
			})
		},
		"cancel": function(id0, id1) {
			return new Route({
				method: "GET",
				url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/duty/" + encodeURIComponent((function (k, v) {
					return v
				})("duty", id1)) + "/swap/cancel"
			})
		},
		"deny": function(id0, id1) {
			return new Route({
				method: "GET",
				url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/duty/" + encodeURIComponent((function (k, v) {
					return v
				})("duty", id1)) + "/swap/deny"
			})
		},
		"approve": function(id0, id1) {
			return new Route({
				method: "GET",
				url: "/api/user/" + encodeURIComponent((function (k, v) {
					return v
				})("id", id0)) + "/duty/" + encodeURIComponent((function (k, v) {
					return v
				})("duty", id1)) + "/swap/approve"
			})
		},
		"list": function() {
			return new Route({
				method: "GET",
				url: "/api/widgets/dutySwapRequests"
			})
		},
	},
}